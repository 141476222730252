<template>
  <!-- 相关列表表格以及公海池列表使用 -->
  <div id="table-panel" style="width: 100%">
    <!-- 表格组件 -->
    <div class="content-main" :class="{ 'open-new-line': newLine }">
      <!-- 骨架屏 -->
      <Wang-skeleton
        type="line"
        :options="skeletonOptions"
        v-if="pageNum === 1"
      >
      </Wang-skeleton>

      <el-table
        ref="tablePanel"
        id="table"
        :data="useLoadMore ? getMoreData : dataList"
        v-show="pageNum !== 1"
        :border="border"
        :height="tableHeight"
        :row-class-name="rowClassName"
        :span-method="spanMethod"
        aria-activedescendant=""
        v-loading="pictLoading"
        :header-row-class-name="headerRowClassName"
        cell-class-name="cellClassName"
        @cell-mouse-enter="cellMouseEnter"
        @header-dragend="headerDragend"
        @cell-mouse-leave="cellMouseLeave"
        @select-all="selectAll"
        @selection-change="selectionChange"
        @select="select"
        @row-click="rowClick"
        @cell-dblclick="cellDoubleClick"
        @toggleSelection="clearSelection"
        highlight-current-row
        :empty-text="$t('label.dashboard.noRecord')"
        :stripe="stripe"
        :style="{ width: '100%', 'min-height': minHeight ? '418px' : '0' }"
      >
        <!-- 最左侧操作列 -->
        <el-table-column
          v-if="showIndex && showTableHeadTool"
          align="center"
          type="index"
          :resizable="false"
          :index="indexMethod"
          :show-overflow-tooltip="true"
          width="55"
          class-name="cerialNumber"
        >
          <!-- 自定义表头部分 -->
          <template #header>
            <!-- 我最近查看的等特殊视图不展示编辑字段按钮 -->
            <div
              v-if="
                pageNum !== 1 &&
                showIndex &&
                showTableHeadTool &&
                viewModify === 'true'
              "
              ref="editTableField"
            >
              <i ref="closeEditTableField"></i>
              <el-popover
                placement="bottom-start"
                width="510"
                :visible-arrow="false"
                trigger="hover"
                v-model="showEditTableFieldPopover"
              >
                <div>
                  <p>
                    <!-- 选择要显示的字段 -->
                    {{ $t("label.view.selectrenderfields") }}
                  </p>
                  <div class="table-head-tool">
                    <div class="pull-left dragBox">
                      <oneShuttle
                        ref="oneShuttle"
                        :unselectedFieldList="unselectedFieldList"
                        :selectedFieldList="selectedFieldList"
                        @memberFun="memberFun"
                      ></oneShuttle>
                    </div>
                    <div class="btns">
                      <el-button
                        size="small"
                        style="margin-top: 10px"
                        @click="hideTool()"
                      >
                        <!-- 取消 -->
                        {{ $t("label.cancel") }}
                      </el-button>
                      <el-button
                        type="primary"
                        size="small"
                        @click="saveSelectedField()"
                      >
                        <!-- 保存 -->
                        {{ $t("label.save") }}
                      </el-button>
                    </div>
                  </div>
                </div>
                <span slot="reference">
                  <svg
                    ref="iconTool"
                    id="iconTool"
                    class="icon admin_s"
                    aria-hidden="true"
                    @mouseover="searchViewDetail"
                  >
                    <use href="#icon-gl"></use>
                  </svg>
                </span>
              </el-popover>
              <!-- <span v-else>序号</span> -->
            </div>
          </template>
        </el-table-column>
        <!-- 序号 -->
        <el-table-column
          v-if="showIndex && !showTableHeadTool"
          :label="$t('label.order.number')"
          align="center"
          type="index"
          :resizable="serialDrop"
          :index="indexMethod"
          width="61"
        >
        </el-table-column>

        <el-table-column
          v-if="checked"
          type="selection"
          :resizable="false"
          :selectable="checkboxSelect"
          key="checked"
          width="51"
        >
        </el-table-column>
        <!-- 表头&内容展示区 -->
        <template v-for="(item, itemIndex) in tableAttr">
          <slot :name="item.schemefieldName" :item="item">
            <!-- html展示数据，一般用于富文本框 -->
            <el-table-column
              v-if="
                !item.click && !item.options && !item.isInput && item.ishtml
              "
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="true"
            >
              <!-- 自定义表头部分 -->
              <template #header v-if="customHeader">
                <!-- <table-head ref='tableHead'
                          :field='item'
                          :name-label='nameLableList[itemIndex]'
                          @changeSortFiled='changeSortFiled'
                          @wordWrap='wordWrap'
                          @locked='locked'>
              </table-head> -->
                <!-- 自定义表头信息 -->
                <div
                  class="tableHeadField"
                  @click="changeSortFiled($event, item)"
                >
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortDir && sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    :width="item.popWidth"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    popper-class="table-popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">
                      {{ $t("vue_label_commonobjects_detail_locking") }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortAsc"
                      @change="changeSortFiled($event, item, 1)"
                    >
                      {{ $t("label.sortasc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortDesc"
                      @change="changeSortFiled($event, item, 2)"
                    >
                      {{ $t("label.sortdesc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-model="item.filterScreen"
                      @change="changeFilter(item)"
                    >
                      {{
                        $t("front-filter-v1-filter-by-name", {
                          name: nameLableList[itemIndex],
                        })
                      }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <div v-html="scope.row[item.schemefieldName]"></div>
              </template>
            </el-table-column>
            <!-- 普通文本类型数据 -->
            <el-table-column
              v-if="
                !item.click && !item.options && !item.isInput && !item.ishtml
              "
              :align="item.align ? item.align : 'left'"
              :width="
                item.colwidth
                  ? item.colwidth
                  : item.schemefieldType === 'D' ||
                    item.schemefieldType === 'F' ||
                    item.schemefieldType === 'c' ||
                    item.schemefieldType === 'N' ||
                    item.schemefieldType === 'T'
                  ? '175'
                  : ''
              "
              :min-width="
                item.colwidth
                  ? item.colwidth
                  : item.schemefieldType === 'D' ||
                    item.schemefieldType === 'F' ||
                    item.schemefieldType === 'c' ||
                    item.schemefieldType === 'N' ||
                    item.schemefieldType === 'T'
                  ? '175'
                  : ''
              "
              :label="item.nameLabel"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="true && item.schemefieldType !== 'B'"
            >
              <template #header v-if="customHeader">
                <div
                  class="tableHeadField"
                  @click="changeSortFiled($event, item)"
                >
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortDir && sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    :width="item.popWidth"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    popper-class="table-popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortAsc"
                      @change="changeSortFiled($event, item, 1)"
                    >
                      {{ $t("label.sortasc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortDesc"
                      @change="changeSortFiled($event, item, 2)"
                    >
                      {{ $t("label.sortdesc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-model="item.filterScreen"
                      @change="changeFilter(item)"
                    >
                      {{
                        $t("front-filter-v1-filter-by-name", {
                          name: nameLableList[itemIndex],
                        })
                      }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <span
                  :style="{
                    'text-align':
                      (item.schemefieldType === 'N' &&
                        item.schemefieldName !== 'leadscore') ||
                      item.schemefieldType === 'c' ||
                      item.schemefieldType === 'P' ||
                      (item.schemefieldType === 'Z' &&
                        (item.expressionType == 'N' ||
                          item.expressionType == 'P' ||
                          item.expressionType == 'c')) ||
                      item.schemefieldType === 'C'
                        ? 'right'
                        : 'left',
                  }"
                >
                  <!-- 日期 -->
                  <span class="cannotEdit" v-if="item.schemefieldType === 'D'">
                    {{
                      scope.row[item.schemefieldName] | dateFormat(countryCode)
                    }}
                  </span>
                  <!-- 日期时间 -->
                  <span
                    class="cannotEdit"
                    v-else-if="item.schemefieldType === 'F'"
                  >
                    {{
                      scope.row[item.schemefieldName]
                        | datetimeFormat(countryCode)
                    }}
                  </span>
                  <!-- 记录类型 -->
                  <span
                    class="cannotEdit"
                    v-else-if="
                      item.schemefieldType === 'R' ||
                      item.schemefieldType === 'MR'
                    "
                  >
                    {{ scope.row[`${item.schemefieldName}ccname`] }}
                  </span>

                  <!-- 复选框 -->
                  <span
                    class="cannotEdit"
                    v-else-if="item.schemefieldType === 'B'"
                  >
                    <el-checkbox
                      :value="
                        scope.row[item.schemefieldName] === 'true' ||
                        scope.row[item.schemefieldName] === '1'
                      "
                      disabled
                    >
                    </el-checkbox>
                    <!-- {{scope.row[item.schemefieldName]  === 'true'? '是': scope.row[item.schemefieldName]  === 'false'? '否' :''}} -->
                  </span>
                  <!-- URL -->
                  <span
                    class="cursor-pointer allow-click"
                    v-else-if="item.schemefieldType === 'U'"
                    @click="jumpToUrl(scope.row[item.schemefieldName])"
                  >
                    {{ scope.row[item.schemefieldName] }}
                  </span>
                  <!-- 标签 -->
                  <span
                    class="cannotEdit"
                    v-else-if="
                      item.schemefieldType === 'X' &&
                      item.schemefieldName === 'cloudcctag'
                    "
                  >
                    <el-tag
                      v-for="tag in scope.row['cloudcctagList']"
                      :key="tag.name"
                      :type="
                        tag.color === 'hui'
                          ? 'info'
                          : tag.color === 'hong'
                          ? 'danger'
                          : tag.color === 'lv'
                          ? 'success'
                          : tag.color === 'lan'
                          ? ''
                          : tag.color === 'huang'
                          ? 'warning'
                          : ''
                      "
                      effect="dark"
                      class="tag"
                    >
                      {{ tag.name }}
                    </el-tag>
                  </span>
                  <!-- 图片 -->
                  <span
                    v-else-if="
                      item.schemefieldType === 'IMG' &&
                      item.expressionType !== 'url'
                    "
                    class="cannotEdit"
                    style="width: 100%; display: inlne-block"
                  >
                    <img
                      v-if="
                        scope.row[item.schemefieldName] !== undefined &&
                        scope.row[item.schemefieldName] !== '' &&
                        scope.row[item.schemefieldName] !== null
                      "
                      :src="imgSrc(scope.row[item.schemefieldName])"
                      style="width: 100%"
                    />
                  </span>
                  <!-- 图片 引用类型 -->
                  <span
                    v-else-if="
                      item.schemefieldType === 'IMG' &&
                      item.expressionType === 'url'
                    "
                    class="cannotEdit"
                  >
                    <img
                      v-if="
                        scope.row[item.schemefieldName] !== undefined &&
                        scope.row[item.schemefieldName] !== '' &&
                        scope.row[item.schemefieldName] !== null
                      "
                      :src="scope.row[item.schemefieldName]"
                    />
                  </span>
                  <!-- 评分 -->
                  <span
                    v-else-if="item.schemefieldType === 'SCORE'"
                    class="cannotEdit stars"
                  >
                    <span v-show="false">{{
                      scope.row[item.schemefieldName] === null ||
                      scope.row[item.schemefieldName] === undefined
                        ? 0
                        : Number(scope.row[item.schemefieldName])
                    }}</span>
                    <span
                      v-for="(star, idx) in Number(item.schemefieldLength) + 1"
                      v-show="idx !== 0"
                      :class="{
                        show2: idx <= Number(scope.row[item.schemefieldName]),
                      }"
                      :key="idx"
                    >
                    </span>
                  </span>
                  <!-- 潜在客户打分 -->
                  <span
                    v-else-if="item.schemefieldName === 'leadscore'"
                    style="display: block; padding-right: 7px"
                    class="cannotEdit"
                  >
                    <span style="width: 25px; display: inline-block">{{
                      scope.row[item.schemefieldName]
                    }}</span>
                    <el-progress
                      :percentage="
                        scope.row[item.schemefieldName] !== undefined &&
                        scope.row[item.schemefieldName] !== null
                          ? Number(scope.row[item.schemefieldName])
                          : 0
                      "
                      :color="customColors"
                      :format="formatProgress"
                    >
                    </el-progress>
                  </span>
                  <!-- 文件 -->
                  <span
                    v-else-if="item.schemefieldType === 'FL'"
                    class="cannotEdit"
                  >
                    <span
                      v-for="file in scope.row[item.schemefieldName + 'List']"
                      :key="file.fileid"
                      class="fileItem"
                      @click="downLoadFile(file)"
                    >
                      {{ file.filename || file.name }}
                    </span>
                  </span>
                  <!-- 任务及事件下的名称对象及相关对象 -->
                  <span
                    v-else-if="
                      item.schemefieldName === 'relateobj' ||
                      item.schemefieldName === 'whoobj'
                    "
                    class="cannotEdit"
                  >
                    <span>
                      {{
                        scope.row[`${item.schemefieldName}`] === "null"
                          ? ""
                          : scope.row[`${item.schemefieldName}`]
                      }}
                    </span>
                  </span>
                  <!-- 相关列表字段跟踪、操作operate -->
                  <span
                    v-else-if="
                      item.schemefieldName === 'operate' ||
                      item.schemefieldName === 'clxx'
                    "
                    class="cannotEdit"
                    v-html="scope.row[item.schemefieldName]"
                  >
                  </span>
                  <!-- 处理富文本展示 -->
                  <span
                    v-else-if="
                      item.schemefieldType == 'Z' && item.expressionType == 'S'
                    "
                    class="cannotEdit"
                    v-html="scope.row[item.schemefieldName]"
                  >
                  </span>
                  <!-- 普通文本 -->
                  <span class="cannotEdit" v-else>
                    {{ scope.row[item.schemefieldName] }}
                  </span>
                  <!--事件及任务下的名称及相关项去除编辑/相关列表文件、
                市场活动 - 表单营销推广取消内联编辑功能
                类型attachtype去除编辑-->
                  <svg
                    v-if="
                      item.schemefieldName !== 'relateobj' &&
                      item.schemefieldName !== 'whoobj' &&
                      item.schfemefieldName !== 'attachtype' &&
                      objId !== 'campaignform'
                    "
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                  <!-- 电话字段回复标识 -->
                  <svg
                    class="icon"
                    v-if="
                      replyPhoneShow &&
                      item.schemefieldType === 'H' &&
                      scope.row[item.schemefieldName]
                    "
                    aria-hidden="true"
                    @click="callPhone(scope.row[item.schemefieldName])"
                    style="cursor: pointer"
                  >
                    <use href="#icon-Call"></use>
                  </svg>
                </span>
                <!-- 内联编辑 -->
                <!-- <editable-cell :value='{value:scope.row[item.schemefieldName]}'
                              :field='item.schemefieldName'
                              :row='scope.row'>
              </editable-cell> -->
              </template>
            </el-table-column>
            <!--  单选下拉框 -->
            <el-table-column
              v-if="item.options && !item.isInput"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="
                item.colwidth
                  ? item.colwidth
                  : item.schemefieldType === 'ct'
                  ? '175'
                  : ''
              "
              :min-width="
                item.colwidth
                  ? item.colwidth
                  : item.schemefieldType === 'ct'
                  ? '175'
                  : ''
              "
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template #header v-if="customHeader">
                <div
                  class="tableHeadField"
                  @click="changeSortFiled($event, item)"
                >
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortDir && sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    :width="item.popWidth"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    popper-class="table-popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortAsc"
                      @change="changeSortFiled($event, item, 1)"
                    >
                      {{ $t("label.sortasc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortDesc"
                      @change="changeSortFiled($event, item, 2)"
                    >
                      {{ $t("label.sortdesc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-model="item.filterScreen"
                      @change="changeFilter(item)"
                    >
                      {{
                        $t("front-filter-v1-filter-by-name", {
                          name: nameLableList[itemIndex],
                        })
                      }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <!-- 等待修改,是否需要添加tooltip -->
              <template slot-scope="scope">
                <span
                  v-if="
                    (item.apiname == 'severity' ||
                      item.apiname == 'priority') &&
                    (item.options[scope.row[item.schemefieldName]] == '高' ||
                      scope.row[item.schemefieldName] == '高')
                  "
                  style="color: red"
                  v-html="showSelectVal(item, scope)"
                >
                </span>
                <span v-else v-html="showSelectVal(item, scope)"> </span>
                <svg
                  class="icon editIconImg"
                  aria-hidden="true"
                  style="display: none"
                  @click="editCell(item, scope.row)"
                >
                  <use href="#icon-pen"></use>
                </svg>
              </template>
            </el-table-column>
            <!-- a标签可跳转类型字段 - 查找、查找多选、所有人 -->
            <el-table-column
              v-if="item.click && !item.isInput && useHyperlinks"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template #header v-if="customHeader">
                <div
                  class="tableHeadField"
                  @click="changeSortFiled($event, item)"
                >
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <svg
                    class="icon sortImg"
                    aria-hidden="true"
                    :style="{
                      transform: sortDir === 'desc' ? 'rotate(180deg)' : '',
                    }"
                    v-show="sortDir && sortField === item.apiname"
                  >
                    <use href="#icon-icon_sort_top"></use>
                  </svg>
                  <el-popover
                    placement="bottom"
                    :width="item.popWidth"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    popper-class="table-popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortAsc"
                      @change="changeSortFiled($event, item, 1)"
                    >
                      {{ $t("label.sortasc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortDesc"
                      @change="changeSortFiled($event, item, 2)"
                    >
                      {{ $t("label.sortdesc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-model="item.filterScreen"
                      @change="changeFilter(item)"
                    >
                      {{
                        $t("front-filter-v1-filter-by-name", {
                          name: nameLableList[itemIndex],
                        })
                      }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <a
                  class="cursor-pointer allow-click"
                  v-if="
                    (objId &&
                      objId === 'campaignform' &&
                      item.schemefieldName === 'name') ||
                    item.schemefieldName === 'subject'
                  "
                  href="javascript:void(0);"
                >
                  <span class="cannotEdit">
                    {{ scope.row[`${item.schemefieldName}`] }}
                  </span>
                </a>
                <!-- 名称 -->
                <a
                  class="cursor-pointer allow-click"
                  v-else-if="
                    (objId &&
                      objId !== 'attachement' &&
                      objId !== 'campaignform' &&
                      item.schemefieldName === 'name') ||
                    item.schemefieldName === 'subject'
                  "
                  :href="nameHrefUrl(scope.row)"
                  @click.exact="jumpTo(item, scope, item.click, true)"
                  @click.ctrl.exact="jumpTo(item, scope, item.click, false)"
                  @click.meta.exact="jumpTo(item, scope, item.click, false)"
                >
                  <span class="cannotEdit">
                    {{ scope.row[`${item.schemefieldName}`] }}
                  </span>
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </a>
                <span
                  class="cursor-pointer allow-click"
                  v-else-if="
                    objId &&
                    objId === 'attachement' &&
                    item.schemefieldName === 'name'
                  "
                  @click="downLoadFile(scope.row)"
                >
                  <span class="cannotEdit">
                    {{ scope.row[`${item.schemefieldName}`] }}
                  </span>
                </span>
                <!-- 所有人 - 非队列 : 可跳转 -->
                <span
                  v-else-if="
                    item.schemefieldType === 'Y' &&
                    item.lookupObj === 'user' &&
                    scope.row[`${item.schemefieldName}`] &&
                    scope.row[`${item.schemefieldName}`].slice(0, 3) === '005'
                  "
                  class="user_box"
                >
                  <headPortrait
                    :headHeight="headHeight"
                    :headWidth="headWidth"
                    :objname="scope.row[`${item.schemefieldName}ccname`]"
                  />
                  <a
                    class="cursor-pointer allow-click"
                    :href="hrefUrl(scope.row, item)"
                    @click.exact="jumpTo(item, scope, item.click, true)"
                    @click.ctrl.exact="jumpTo(item, scope, item.click, false)"
                    @click.meta.exact="jumpTo(item, scope, item.click, false)"
                  >
                    {{ scope.row[`${item.schemefieldName}ccname`] }}
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </a>
                </span>
                <!-- 所有人 - 队列 : 不可跳转 -->
                <span
                  v-else-if="
                    item.schemefieldType === 'Y' &&
                    scope.row[`${item.schemefieldName}`] &&
                    scope.row[`${item.schemefieldName}`].slice(0, 3) === 'que'
                  "
                >
                  {{ scope.row[`${item.schemefieldName}ccname`] }}
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </span>

                <!-- 查找字段 公海池字段不支持跳转 -->
                <a
                  class="cursor-pointer allow-click"
                  v-else-if="
                    item.schemefieldType !== 'MR' &&
                    item.apiname !== 'marketsea'
                  "
                  :href="hrefUrl(scope.row, item)"
                  @click.exact="jumpTo(item, scope, item.click, true)"
                  @click.ctrl.exact="jumpTo(item, scope, item.click, false)"
                  @click.meta.exact="jumpTo(item, scope, item.click, false)"
                >
                  <!-- 名称和相关项 -->
                  <span
                    class="cannotEdit"
                    v-if="
                      item.schemefieldName === 'relateid' ||
                      item.schemefieldName === 'whoid'
                    "
                  >
                    {{
                      item.schemefieldName === "relateid" ||
                      item.schemefieldName === "whoid"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                  </span>
                  <span v-else class="cannotEdit">
                    {{
                      item.schemefieldType === "Y" ||
                      item.schemefieldType === "MR" ||
                      item.schemefieldType === "M"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </span>
                </a>
                <!-- 查找多选 -->
                <a
                  class="cursor-pointer"
                  v-else-if="item.schemefieldType === 'MR'"
                >
                  <span class="cannotEdit">
                    {{ scope.row[`${item.schemefieldName}ccname`] }}
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </span>
                </a>
              </template>
            </el-table-column>
            <!-- 不使用a标签 - 查找、查找多选、所有人 -->
            <el-table-column
              v-if="item.click && !item.isInput && !useHyperlinks"
              :align="item.align ? item.align : 'left'"
              :label="item.nameLabel"
              :width="item.colwidth"
              :prop="item.schemefieldName"
              :fixed="item.fixed"
              :sortable="item.sortable"
              :show-overflow-tooltip="columnTooltip"
            >
              <template #header v-if="customHeader">
                <div
                  class="tableHeadField"
                  @click="changeSortFiled($event, item)"
                >
                  <el-popover
                    placement="top-start"
                    :open-delay="openDelay"
                    trigger="hover"
                    popper-class="my-tooltip table-head-tooltip"
                    :content="nameLableList[itemIndex]"
                  >
                    <span slot="reference">
                      {{ nameLableList[itemIndex] }}
                    </span>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    :width="item.popWidth"
                    trigger="hover"
                    :visible-arrow="false"
                    class="popover-span"
                    popper-class="table-popover-span"
                    :ref="`${item.schemefieldName}-popover`"
                    v-if="showLock"
                  >
                    <el-checkbox v-model="item.locked" @change="locked(item)">{{
                      $t("vue_label_commonobjects_detail_locking")
                    }}</el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortAsc"
                      @change="changeSortFiled($event, item, 1)"
                    >
                      {{ $t("label.sortasc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-if="isSort(item)"
                      v-model="item.sortDesc"
                      @change="changeSortFiled($event, item, 2)"
                    >
                      {{ $t("label.sortdesc") }}
                    </el-checkbox>
                    <el-checkbox
                      v-model="item.filterScreen"
                      @change="changeFilter(item)"
                    >
                      {{
                        $t("front-filter-v1-filter-by-name", {
                          name: nameLableList[itemIndex],
                        })
                      }}
                    </el-checkbox>
                    <span slot="reference">
                      <svg class="icon lockedImg" aria-hidden="true">
                        <use href="#icon-xiajiantou"></use>
                      </svg>
                    </span>
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <!-- 查找字段 -->
                <span
                  class="cursor-pointer allow-click"
                  v-if="item.schemefieldType !== 'MR'"
                  @click.exact="jumpTo(item, scope, item.click, true)"
                  @click.ctrl.exact="jumpTo(item, scope, item.click, false)"
                  @click.meta.exact="jumpTo(item, scope, item.click, false)"
                >
                  <!-- 名称和相关项 -->
                  <span
                    class="cannotEdit"
                    v-if="
                      item.schemefieldName === 'relateid' ||
                      item.schemefieldName === 'whoid'
                    "
                  >
                    {{
                      item.schemefieldName === "relateid" ||
                      item.schemefieldName === "whoid"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                  </span>
                  <span v-else class="cannotEdit">
                    {{
                      item.schemefieldType === "Y" ||
                      item.schemefieldType === "MR" ||
                      item.schemefieldType === "M"
                        ? scope.row[`${item.schemefieldName}ccname`]
                        : scope.row[`${item.schemefieldName}`]
                    }}
                    <svg
                      class="icon editIconImg"
                      aria-hidden="true"
                      style="display: none"
                      @click="editCell(item, scope.row)"
                    >
                      <use href="#icon-pen"></use>
                    </svg>
                  </span>
                </span>
                <span v-else class="cannotEdit">
                  {{
                    item.schemefieldType === "Y" ||
                    item.schemefieldType === "MR" ||
                    item.schemefieldType === "M" ||
                    item.schemefieldName === "relateid" ||
                    item.schemefieldName === "whoid"
                      ? scope.row[`${item.schemefieldName}ccname`]
                      : scope.row[`${item.schemefieldName}`]
                  }}
                  <svg
                    class="icon editIconImg"
                    aria-hidden="true"
                    style="display: none"
                    @click="editCell(item, scope.row)"
                  >
                    <use href="#icon-pen"></use>
                  </svg>
                </span>
              </template>
            </el-table-column>
          </slot>
        </template>

        <!-- 操作列  itemObj有值证明是相关列表的操作列-->
        <el-table-column
          v-if="tableButton.buttonList && tableButton.buttonList.length"
          :label="''"
          :align="tableButton.align || 'center'"
          style="text-align: center"
          :width="tableButton.width || '80'"
        >
          <template slot-scope="scope">
            <!-- 如果操作列无操作项，隐藏三角形 -->
            <el-popover
              placement="bottom-end"
              popper-class="pop_small"
              trigger="click"
              :visible-arrow="false"
              v-show="(objModifyAll || objModify || objDelete) && showButtonCol"
            >
              <svg
                slot="reference"
                class="icon tableButton"
                aria-hidden="true"
                @click="handleclick(scope.row)"
              >
                <use href="#icon-xiala"></use>
              </svg>
              <ul>
                <li
                  v-for="(btnItem, index) in tableButton.buttonList"
                  v-show="ifButtonShow(scope, btnItem)"
                  :key="btnItem.action"
                  @click="handleTableAction(scope, btnItem)"
                >
                  <span
                    class="cursor-pointer"
                    :disabled="btnItem.disabled"
                    :key="index"
                  >
                    {{ btnItem.label }}
                  </span>
                </li>
              </ul>
            </el-popover>
          </template>
        </el-table-column>

        <slot></slot>
      </el-table>

      <!-- 表格内联编辑 -->
      <el-dialog
        :title="$t('label.change')"
        :visible.sync="dialogVisible"
        top="15%"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :append-to-body="true"
        class="edit_box"
      >
        <p class="pull-left" style="line-height: 30px; margin-right: 10px">
          {{ field }}
        </p>
        <editable-cell
          v-if="dialogVisible"
          ref="editableCell"
          :field="field"
          :schemefieldLength="schemefieldLength"
          :options="editableCellOptions"
          :value="toChangeValue"
          :isFilter="false"
          :input-type="inputType"
          :err-tip="errTip"
          :precision="precision"
          :min="min"
          :max="max"
          origin-type="table"
          width="95%"
          @remoteSearch="remoteSearch"
        >
        </editable-cell>

        <p
          style="text-align: left; padding-top: 10px; clear: both"
          v-if="showApply2Others"
        >
          <!-- 是否应用于选中数据 -->
          <el-checkbox v-model="changeAll">{{
            $t("vue_label_commonobjects_view_change_all_selected_records")
          }}</el-checkbox>
        </p>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="saveChange"
            :loading="isSaveBtnLoading"
          >
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </span>
      </el-dialog>

      <!-- 查找/查找多选 -->
      <el-dialog
        :title="$t('label.searchs')"
        width="60%"
        top="5%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        :before-close="beforeClose"
        append-to-body
      >
        <div
          :style="{
            'min-height': dialogBodyHeight,
            overflow: 'auto',
            padding: '0 20px',
          }"
        >
          <search-table
            ref="searchTable"
            :dialogBodyHeight="dialogBodyHeight"
            :fieldId="relevantFieldId"
            :checked="multiChecked"
            :relevant-objid="relevantObjId"
            :relevant-objapi="relevantObjApi"
            :relevant-prefix="relevantPrefix"
            :projectId="projectId"
            :optionCheckedArr="optionCheckedArr"
            @changeSelect="changeSelect"
            @setFieldReltaion="setFieldReltaion"
          />
        </div>
      </el-dialog>
    </div>
    <!-- 服务报告预览 -->
    <file-preview
      :show="showPreview"
      :showUrl="previewUrl"
      :showData="showPreviewData"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>

    <!-- 业务机会/报价单/订单/发票/采购订单（相关列表内联编辑）切换价格手册提示 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="pricIntro"
      append-to-body
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 更改已选取的价格手册，将删除全部现有的产品。是否更改为新的价格手册？ -->
        {{ $t("c731") }}
      </div>
      <span slot="footer">
        <el-button @click="cancelPricChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmPricChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from "vuex";
import { sortFidld } from "@/utils/field/sortField.js";
import { emptyArray } from "@/utils/array";
import EditableCell from "./EditableCell.vue";
import draggable from "vuedraggable";
import INPUTTYPE from "@/config/enumCode/inputType.js";
import * as CommonObjApi from "./api";
import oneShuttle from "@/components/ViewBox/oneShuttle.vue";
import filePreview from "@/components/FileView/filePreview";
import createButton from "@/utils/Button";
import headPortrait from "@/views/gantt/resourceManage/components/headPortrait";
import debounce from "lodash.debounce";
import * as callCenterApi from "./api.js"; // 呼叫中心接口
import Vue from "vue";
import vueWangIview from "vue-wang-iview"; // 骨架屏
import "vue-wang-iview/dist/vue-wang-iview.css";
import { consoleSaveTab } from "@/views/home/api.js";
Vue.use(vueWangIview);
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: {
    EditableCell,
    draggable,
    oneShuttle,
    filePreview,
    headPortrait,
  },
  props: {
    // 是否开启内敛编辑（相关列表不支持内联编辑）
    islineEdit: {
      type: Boolean,
      default: true,
    },
    // 当前组件是否不是被search-table引用，如果是，即该值为false，需要如下处理
    // 1、不支持点击cell事件跳转
    // 2、不支持表格内敛编辑
    isClickCell: {
      type: Boolean,
      default: true,
    },
    // table是否开启滚动加载
    useLoadMore: {
      type: Boolean,
      default: false,
    },
    // 是否是销售漏斗页面引用
    isSalesFunnel: {
      type: Boolean,
      default: false,
    },
    // 相关列表所属的主记录id
    mainId: {
      type: String,
      default: "",
    },
    // 是否开启滚动加载
    closeLoadMore: {
      type: Boolean,
      defalt: false,
    },
    // 序号页是否可以拖拽
    serialDrop: {
      type: Boolean,
      defalt: false,
    },
    // 使用自定义表头
    customHeader: {
      type: Boolean,
      defalt: true,
    },
    // 页码
    pageNum: {
      type: Number,
      defalt: 1,
    },
    currentPage: {
      type: Number,
      defalt: 1,
    },
    //每页多少条数据
    pageSizeList: {
      type: Number,
      defalt: 50,
    },
    // 加载标识
    pictLoading: {
      type: Boolean,
      defalt: false,
    },
    // 每一列数据的 id
    id: {
      type: String,
      default: "id",
    },
    // 是否条纹状表格
    stripe: {
      type: Boolean,
      default: false,
    },
    // 当前视图是否可编辑
    viewModify: {
      type: String,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    // 是否开启分页
    pagination: {
      type: Boolean,
      default: true,
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
    // 表格数据
    pageObj: {
      type: Object,
      default: null,
    },
    // 可跳转链接是否使用a标签
    useHyperlinks: {
      type: Boolean,
      default: true,
    },
    // 是否展示编辑表格字段
    showTableHeadTool: {
      type: Boolean,
      default: true,
    },
    // 表头
    tableAttr: {
      type: Array,
      default: null,
    },
    // 编辑表头已选字段
    viewSelectedFieldList: {
      type: Array,
      dafault: [],
    },
    // 编辑表头可选字段
    viewUnselectedFieldList: {
      type: Array,
      dafault: [],
    },
    // 数据排序
    sortable: {
      type: Boolean,
      default: false,
    },
    // 选择框
    checked: {
      type: Boolean,
      default: false,
    },
    // 按钮
    tableButton: {
      // 按钮
      type: [Array, Object],
      default: () => [],
    },
    // 操作按钮控制函数
    tableButtonCtrl: {
      type: Function,
      default: undefined,
    },
    // 表格高度
    tableHeight: {
      type: [String, Number, undefined],
      default: undefined,
    },
    // 是否开启边框
    border: {
      type: Boolean,
      default: false,
    },
    // 选中数量
    checkedCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    // 是否开启序号
    showIndex: {
      type: Boolean,
      default: true,
    },
    // 单选框是否可以选中
    selectable: {
      type: [Function, undefined],
      default: undefined,
    },
    selectedList: {
      type: Array, // 已选中列
      default: () => [],
    },
    autoChecked: {
      // 根据传入的id自动选中
      type: Function,
    },
    spanMethod: {
      // 合并列算法
      type: [Function, undefined],
      default: undefined,
    },
    // 是否开启分页记忆选中功能 现版本配合vuex使用
    memory: {
      type: Boolean,
      default: false,
    },
    headerRowClassName: {
      type: [Function, String],
      default: undefined,
    },
    rowClassName: {
      type: [Function, undefined],
      default: undefined,
    },
    // 是否展开新行
    newLine: {
      type: Boolean,
      default: false,
    },
    // 是否设置min-height
    minHeight: {
      type: Boolean,
      default: false,
    },
    // 对象id，判断对象是否有编辑、删除权限
    objId: {
      type: String,
      default: "",
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 视图id
    viewId: {
      type: String,
      default: "",
    },
    // 使用父组件传来的objectApi，父组件使用路由传递的值
    objectApi: {
      type: String,
      default: "",
    },
    // 是否显示锁定功能
    showLock: {
      type: Boolean,
      default: true,
    },
    // 相关列表的对象信息
    itemObj: {
      type: Object,
      default: () => {},
    },
    // 排序字段
    sortField: {
      type: String,
      default: "",
    },
    // 排序方式
    sortDir: {
      type: String,
      default: "",
    },
    backchecked: {
      type: Array, //返回选中的数据
      default: () => [],
    },
    showScreen: {
      type: Boolean, //是否展示筛选器
    },
    filterField: {
      type: Object, //按‘字段名’排序字段
    },
  },
  data() {
    return {
      isJumpFlag: true, // 是否跳转的标识 false不跳转（比如控制台应用程序--相关列表---ctrl点击记录名称、查找字段不进行跳转）
      level2Id: "", // 二级菜单选中状态
      // 分页页数大小
      pageSizeV: 5,
      getMoreData: this.pageObj.dataList.slice(0, 20),
      selectWrap: null,
      // 支持内联编辑字段的api
      isEditApi: "",
      openDelay: 500, // 表头悬浮提示延迟时间
      showSearchTable: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      fieldId: "",
      multiChecked: false,
      relevantFieldId: "",
      relevantObjId: "",
      relevantObjApi: "",
      relevantPrefix: "",
      relevantObjectApi: "",
      countryCode: $cookies.get("countryCode"),
      showEditTableFieldPopover: false,
      skeletonOptions: {
        active: true,
        row: 18,
        column: 5,
        width: "18vw",
        height: "20px",
        justifyContent: "space-around",
      },
      schemefieldLength: 0, // 评分字段最大分值
      searchField: "",
      errTip: this.$i18n.t("label.vlidaterule.message"),
      field: "", // 要修改的字段名称
      fieldApiName: "", // 要修改的字段ApiName
      changeAll: false, // 是否应用于全部选中数据
      editableCellOptions: [],
      inputType: "", // 要修改数据显示类型
      toChangeValue: null, //要修改的数据
      dialogVisible: false,
      editModeEnabled: false,
      origin: -1, // 选中数据起点
      pin: false, // 是否按住shift键
      dataList: this.pageObj.dataList,
      memoryCheckList: [],
      pageSize: [10, 20, 50, 100],
      showTable: false,
      pageInfo: true,
      checkedList: [],
      columnTooltip: true,
      isSelectList: [],
      selected: [],
      selection: [],
      total: 0,
      // 数据总条数是否应用于批量编辑（暂时关闭）
      showApply2Others: false,
      nameLableList: [],
      selectedFieldList: [], // 选中表头
      unselectedFieldList: [], // 未来选中表头
      allSelectedFieldList: [], // 可选表头
      editableCellAttr: null, //单元格编辑字段信息
      // 编辑单元格时的rowId
      dataId: "",
      // 内联编辑时当前记录的数据，用于查找筛选条件匹配
      rowData: {},
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      precision: 0,
      min: 0,
      max: 0,
      objModifyAll: null, // 管理员权限
      objModify: null, // 对象编辑权限
      objDelete: null, // 对象删除权限
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      inlineedit: false, // 全局内联编辑权限
      echoSelected: [],
      index: 1,
      str: "",
      projectId: "",
      // 文件预览参数
      showPreview: false,
      showPreviewData: {},
      previewUrl: "",
      isSaveBtnLoading: false, //保存加载状态
      loadingOneShuttle: false,
      allRow: [], //多选选中的表格数据
      jubuloading: null, //tbody局部 loading
      imgsUrl: `${localStorage.getItem(
        "domainName"
      )}/chatterzone.action?m=showPhoto&userid=`, //查找用户字段Y加头像显示需要
      imgssurl: `&binding=${this.$CCDK.CCToken.getToken()}`, //查找用户字段Y加头像显示需要
      headWidth: "23px",
      headHeight: "23px",
      // 节流请求数据间隔
      refreshDate: "",
      // loading: {}, //tbody局部 loading
      optionCheckedArr: [],
      replyPhoneShow: false,
      // 列表页内联编辑是否切换价格手册弹框
      pricIntro: false,
      // 价格手册行数据
      priceRow: [],
      // 修改价格手册是否触发确定按钮
      priceIsDelSon: false,
      // 记录id
      recordId: "",
    };
  },
  created() {
    this.userUrl = this.$cookies.get("domainName");
    this.memoryCheckList = this.memoryCheckList.concat(this.selectedList);
    this.getLeadHeat();
    this.inlineedit = localStorage.getItem("inlineedit");
  },
  beforeDestroy() {
    this.$bus.$off("hideTool", this.hideToolFn);
    window.removeEventListener("keydown", this.keydownFn);
    window.removeEventListener("keyup", this.keyupFn);
    window.removeEventListener("scroll", this.loadMoreData);
  },
  mounted() {
    // 监听切换菜单时关闭弹框
    this.$bus.$on("hideTool", this.hideToolFn);
    // 如果表格宽度小于847  分页部分将折行显示 逻辑控制隐藏左边部分
    if (
      document.getElementById("table-panel") &&
      document.getElementById("table-panel").offsetWidth < 847
    ) {
      this.pageInfo = false;
    }
    this.showTable = true;
    // 获取键盘事件
    window.addEventListener("keydown", this.keydownFn);
    window.addEventListener("keyup", this.keyupFn);
    this.getObjectPermission(this.objId);
    if (!this.closeLoadMore) {
      // 为table注册滚动加载事件
      this.tableGetMore();
    }
    this.replyPhoneShowClick();
    this.selectWrap = document.querySelector(".el-table__body-wrapper");
    if (this.selectWrap && this.useLoadMore) {
      this.selectWrap.addEventListener("scroll", this.loadMoreData);
    }
  },
  methods: {
    loadMoreData() {
      let sign = 100;
      const scrollDistance =
        this.selectWrap.scrollHeight -
        this.selectWrap.scrollTop -
        this.selectWrap.clientHeight;
      if (scrollDistance <= sign) {
        this.pageSizeV++;
        this.getMoreData = this.dataList.slice(0, this.pageSizeV * 10);
      }
    },
    keydownFn(code) {
      // 获取键盘按住事件
      // 判断是否按住shift键，是就把pin值赋为true
      if (code.keyCode === 16 && code.shiftKey) {
        this.pin = true;
      }
    },
    keyupFn(code) {
      // 获取键盘按住事件
      // 判断是否松开shift键，是就把pin值赋为true
      if (code.keyCode === 16) {
        this.pin = false;
      }
    },
    hideToolFn(data) {
      this.showEditTableFieldPopover = data;
    },
    // 电话回复图标判断
    replyPhoneShowClick() {
      let callcenterConf = JSON.parse(localStorage.getItem("callcenterConf"));
      if (callcenterConf?.tooltype) {
        this.replyPhoneShow = true;
      } else {
        this.replyPhoneShow = false;
      }
      // if (this.countryCode == "CN") {
      //   let paramsInfo = {
      //     intergrateType: "tianrun",
      //   };
      //   // 获取呼叫中心设置
      //   callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
      //     if (resInfo.result && resInfo.data) {
      //       if (resInfo.data.isEnable == "0") {
      //         this.replyPhoneShow = false;
      //       } else if (resInfo.data.isEnable == "1") {
      //         let params = {
      //           bindUserId: localStorage.getItem("userId"),
      //         };
      //         callCenterApi.getBindByCnoOrUserId(params).then((res) => {
      //           if (res.data.length !== 0) {
      //             this.replyPhoneShow = true;
      //           } else {
      //             this.replyPhoneShow = false;
      //           }
      //         });
      //       }
      //     }
      //   });
      // } else {
      //   let paramsInfo = {
      //     intergrateType: "aws",
      //   };
      //   // 获取呼叫中心设置
      //   callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
      //     if (resInfo.result && resInfo.data) {
      //       if (resInfo.data.isEnable == "0") {
      //         this.replyPhoneShow = false;
      //       } else if (resInfo.data.isEnable == "1") {
      //         this.replyPhoneShow = true;
      //       }
      //     }
      //   });
      // }
    },

    //添加操作项
    handleclick(row) {
      if (this.itemObj === undefined) {
        if (row.template) {
          if (row.template == "false") {
            this.tableButton.buttonList.splice(
              3,
              1,
              createButton({
                // 标记为模板
                label: this.$i18n.t(
                  "front-project-module-v1-market-is-template"
                ),
                action: "marktemplate",
              })
            );
          } else {
            this.tableButton.buttonList.splice(
              3,
              1,
              createButton({
                // 取消标记模板
                label: this.$i18n.t(
                  "front-project-module-v1-unmarket-is-template"
                ),
                action: "unmarktemplate",
              })
            );
          }
        } else {
          this.tableButton.buttonList.splice(3, 1);
        }
      } else if (row.billingaddress || row.shippingaddress) {
        this.tableButton.buttonList.splice(4, 1);
      } else {
        this.tableButton.buttonList.splice(3, 1);
      }
    },
    //打开局部loading
    openFullScreen() {
      if (!this.jubuloading) {
        this.jubuloading = this.$loading({
          lock: true,
          text: this.$i18n.t("label.file.loading"),
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255,0.9)",
          target: document.getElementsByClassName("el-table__body-wrapper")[1],
        });
      }
    },
    //关闭局部loading
    closeFullScreen() {
      this.$nextTick(() => {
        this.jubuloading.close();
        this.jubuloading = null;
      });
    },
    checkboxSelect(row, rowIndex) {
      if (
        row.their_task &&
        window.location.href.substring(window.location.href.length - 3) == "p03"
      ) {
        return false; // 禁用
      } else {
        return true; // 不禁用
      }
    },
    //打开局部loading
    openFullScreen() {
      if (!this.jubuloading) {
        this.jubuloading = this.$loading({
          lock: true,
          text: this.$i18n.t("label.file.loading"),
          spinner: "el-icon-loading",
          background: "rgba(255, 255, 255,0.9)",
          target: document.getElementsByClassName("el-table__body-wrapper")[1],
        });
      }
    },
    //关闭局部loading
    closeFullScreen() {
      this.$nextTick(() => {
        this.jubuloading.close();
        this.jubuloading = null;
      });
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 获取对象权限
    getObjectPermission(objId) {
      if (objId !== null && objId !== "") {
        if (this.itemObj === undefined) {
          CommonObjApi.getObjectPermission({ id: objId }).then((res) => {
            this.objModifyAll = res.data.modifyAll || false;
            this.objModify = res.data.modify || false;
            this.objDelete = res.data.delete || false;
          });
        } else {
          //表单营销推广
          if (
            this.itemObj != undefined &&
            this.itemObj.objid === "campaignform"
          ) {
            this.objModifyAll = true;
          } else {
            this.objModifyAll = false;
            this.objModify = this.itemObj.services.modify;
            this.objDelete = this.itemObj.services.delete;
          }
        }
      } else {
        this.objModifyAll = false;
        this.objModify = false;
        this.objDelete = false;
      }
    },
    // 强制刷新
    changeTableAttr(tableAttr) {
      this.nameLableList = [];
      tableAttr &&
        tableAttr.forEach((item) => {
          this.nameLableList.push(item.nameLabel);
        });
      this.$nextTick(() => {
        this.$refs.tablePanel && this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
      });
    },
    // 隐藏设置字段工具
    hideTool(scope) {
      this.showEditTableFieldPopover = false;
      this.$refs.closeEditTableField.click();
    },
    // 查找/查找多选
    remoteSearch(val) {
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
        let checkoutList = [...this.editableCellOptions].filter((x) =>
          [...val].some((y) => x.value === y)
        ); // 取并集
        let option = [];
        checkoutList.forEach((res) => {
          option.push({
            id: res.value,
            name: res.label,
          });
        });
        this.optionCheckedArr = option;
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      this.priceRow = row;
      let options = [];
      let optionValue = [];
      this.optionCheckedArr = [];
      this.showSearchTable = false;

      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
        this.optionCheckedArr = row;
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
        optionValue.push(row.data.id);
        optionValue = optionValue.toString();
        this.optionCheckedArr.push(row.data);
      }

      if (row.data && row.field.name == "价格手册名称") {
        /**
         * 订单、报价单、发票、采购订单、业务机会等对象（相关列表内联编辑）修改价格手册时，需查看相关子产品是否有数据，
         * 有则给出提示语，否则直接修改价格手册
         * @param {String} objId 对象id
         * @param {String} recordId 相关记录id
         */
        let params = {
          objId: this.objId,
          recordId: this.recordId,
        };
        CommonObjApi.existRelatedProductInfo(params).then((res) => {
          if (res.data == "true") {
            this.pricIntro = true;
          } else {
            this.editableCellOptions = options;
            this.toChangeValue = { value: optionValue };
          }
        });
      } else {
        this.editableCellOptions = options;
        this.toChangeValue = { value: optionValue };
      }
    },
    // 取消（内联编辑是否切换价格手册弹框）
    // 取消修改价格手册，价格手册赋回老值
    cancelPricChange() {
      this.pricIntro = false;
    },
    // 确定修改价格手册（内联编辑是否切换价格手册弹框）
    // 确定修改价格手册，价格手册赋新值，并且保存时调用删除子产品数据的接口（deleteRelatedProductInfo）
    confirmPricChange() {
      this.pricIntro = false;
      let options = [];
      let optionValue = [];
      this.optionCheckedArr = [];
      this.showSearchTable = false;
      if (this.priceRow instanceof Array) {
        this.priceRow.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
          optionValue.push(ele.id);
        });
        this.optionCheckedArr = this.priceRow;
      } else {
        options.push({
          label: this.priceRow.data.name,
          value: this.priceRow.data.id,
        });
        optionValue.push(this.priceRow.data.id);
        optionValue = optionValue.toString();
        this.optionCheckedArr.push(this.priceRow.data);
      }
      this.editableCellOptions = options;
      this.toChangeValue = { value: optionValue };
      this.priceIsDelSon = true; //修改价格手册弹框触发了确定按钮
    },
    /**
     * 内联编辑查找字段时，设置查找筛选条件。
     * 注意：筛选条件如果设置field才需要前端传值，设置的筛选条件字段必须在表格列中出现才有效
     * @param {Array} filterFieldIds:设置筛选条件的数组，包含条件对应的字段id和字段类型
     */
    setFieldReltaion(filterFieldIds) {
      let values = [""];
      if (Array.isArray(filterFieldIds) && filterFieldIds.lenght > 0) {
        values.push(this.dataId);
        let attrMap = new Map();
        // 表头数据,生成字段id和apiname的map
        this.tableAttr.forEach((item) => {
          attrMap.set(item.id, item.schemefieldName);
        });
        // 找到对应筛选条件的值
        filterFieldIds.forEach((item) => {
          // 根据字段id获的该字段的props
          let fieldProps = attrMap.get(item.fieldid);
          if (fieldProps) {
            // 该字段的被设置了筛选条件，将值放入筛选条件查询参数中
            let val = this.rowData[fieldProps] || "";
            values.push(val);
          } else {
            // 即使没有值也要存空字符串，必须保证顺序
            values.push("");
          }
        });
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 下拉框类型字段显示值处理
    showSelectVal(item, scope) {
      let showContnet = "";
      let option =
        item.options &&
        item.options.find((option) => {
          return option.codekey === scope.row[item.schemefieldName];
        });
      if (option !== undefined) {
        showContnet = option.codevalue;
      } else {
        showContnet = scope.row[item.schemefieldName] || "";
      }
      return showContnet;
    },
    // 保存视图选中字段
    saveSelectedField() {
      this.$refs.oneShuttle.curGroupLists();
      this.selectedFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      this.$nextTick(() => {
        if (this.selectedFieldList.length > 15) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
          );
        } else if (this.selectedFieldList.length === 0) {
          this.$message.warning(
            this.$i18n.t("vue_label_commonobjects_view_notice_atleast1field")
          );
        } else {
          this.showEditTableFieldPopover = false;
          this.$emit("saveSelectedField", this.str);
          this.selectedFieldList = [];
        }
      });
    },
    // 显示编辑按钮
    cellMouseEnter(row, column, cell, event) {
      this.recordId = row.id; // 记录id
      if (!this.islineEdit) return;
      // 判断是否开启内联编辑
      if (
        this.$store.state.userInfoObj.enableInlineEdit === "false" ||
        !this.isClickCell
      )
        return;
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      // 相关列表页个人客户下的联系人'name'字段编辑权限处理
      if (attr[0] && attr[0].apiname === "name") {
        if (
          row.objApi &&
          row.objApi === "Contact" &&
          this.isNotPersonaccount === false
        ) {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                "none")
            : "";
        } else {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
            : "";
        }
      }
      // 相关列表页'币种'字段编辑权限处理
      if (attr[0] && attr[0].apiname === "currency") {
        // 订单、报价单、发票、采购订单、业务机会（相关列表）币种'子记录'不为空时不可编辑
        if (
          this.objectApi === "quotedetail" ||
          this.objectApi === "InvoicesItems" ||
          this.objectApi === "cloudccorderitem" ||
          this.objectApi === "purchaseorderitem" ||
          this.objectApi === "opportunitypdt"
        ) {
          /**
           * 订单、报价单、发票、采购订单、业务机会（相关列表页内联编辑）修改币种时，
           * 需查看相关子产品是否有数据，有则不可内联编辑，无则显示编辑按钮
           * @param {String} objId 对象id
           * @param {String} recordId 相关记录id
           */
          // let params = {
          //   objId: this.objId,
          //   recordId: this.mainId,
          // };
          // CommonObjApi.existRelatedProductInfo(params).then((res) => {
          //   if (res.data == "true") {
          //     cell.getElementsByClassName("editIconImg")[0]
          //       ? (cell.getElementsByClassName("editIconImg")[0].style.display =
          //           "none")
          //       : "";
          //   } else {
          //     cell.getElementsByClassName("editIconImg")[0]
          //       ? (cell.getElementsByClassName("editIconImg")[0].style.display =
          //           "")
          //       : "";
          //   }
          // });
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                "none")
            : "";
        }
        // 除（订单、报价单、发票、采购订单、业务机会）其他对象币种字段支持内联编辑
        // 这里特殊判断了'产品'对象，产品任何情况下都不支持编辑
        else if (
          (this.objectApi != "quotedetail" ||
            this.objectApi != "InvoicesItems" ||
            this.objectApi != "cloudccorderitem" ||
            this.objectApi != "purchaseorderitem" ||
            this.objectApi != "opportunitypdt") &&
          this.objectApi != "product"
        ) {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
            : "";
        }
        // '产品'对象，不支持编辑
        else if (this.objectApi == "product") {
          cell.getElementsByClassName("editIconImg")[0]
            ? (cell.getElementsByClassName("editIconImg")[0].style.display =
                "none")
            : "";
        }
      }

      // 图片、文件字段、币种（币种是否可内联编辑不走改判断）不可内联编辑
      if (
        this.inlineedit &&
        attr[0] &&
        attr[0].schemefieldType !== "IMG" &&
        attr[0].schemefieldType !== "FL" &&
        attr[0].apiname !== "currency" &&
        attr[0].apiname !== "name"
      ) {
        // 通过对象编辑权限和字段编辑权限判断是否展示
        attr[0] &&
        attr[0].modify === "true" &&
        (this.objModifyAll || this.objModify) &&
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display = "")
          : "";
      }
      // 实际人工成本、预计人工成本，项目任务/问题-总持续时长不展示列表行内编辑
      if (
        attr[0] &&
        (this.objectApi === "cloudcc_real_labor_costs" ||
          this.objectApi === "cloudccPlanCost" ||
          ((this.objectApi === "cloudccTask" ||
            this.objectApi === "CloudccProblem") &&
            attr[0].apiname === "continue_date") ||
          attr[0].apiname === "taskOrProblem" ||
          (this.objectApi === "CloudccProjectActualWorkList" &&
            attr[0].apiname === "project_name") ||
          attr[0].apiname === "their_project_name" ||
          attr[0].apiname === "project_name" ||
          attr[0].apiname === "their_project" ||
          (this.objectApi == "cloudccSubtask" &&
            attr[0].apiname === "their_milestone") ||
          attr[0].apiname === "principal" ||
          attr[0].apiname === "their_task" ||
          (this.objectApi === "cloudccSubtask" &&
            attr[0].apiname === "continue_date"))
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        attr[0] &&
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        cell.getElementsByClassName("editIconImg")[0]
          ? (cell.getElementsByClassName("editIconImg")[0].style.display =
              "none")
          : "";
      }
    },
    // 隐藏编辑按钮
    cellMouseLeave(row, column, cell, event) {
      if (!this.islineEdit) return;
      // 判断是否开启内联编辑
      if (
        this.$store.state.userInfoObj.enableInlineEdit === "false" ||
        !this.isClickCell
      )
        return;
      cell.getElementsByClassName("editIconImg")[0]
        ? (cell.getElementsByClassName("editIconImg")[0].style.display = "none")
        : "";
    },
    // 查询视图详细信息
    searchViewDetail() {
      if (
        (this.viewId === "0" ||
          this.viewId === "1" ||
          this.viewId === "2" ||
          this.viewId === "3") &&
        this.viewModify !== "true"
      ) {
      } else {
        this.getFieldSetup();
      }
    },
    getFieldSetup() {
      this.$refs.oneShuttle.loadingSeleccted = true;
      this.selectedFieldList = [];
      this.unselectedFieldList = [];
      this.allSelectedFieldList = [];

      CommonObjApi.getFieldSetup({
        objId: this.objId,
        viewId: this.viewId,
      })
        .then((res) => {
          this.selectedFieldList = res.data.viewSelectedFieldList;
          this.allSelectedFieldList = res.data.viewUnselectedFieldList;
          this.unselectedFieldList = res.data.viewUnselectedFieldList;
          this.$nextTick(() => {
            this.$refs.oneShuttle.selectedFieldList = this.selectedFieldList;
            this.$refs.oneShuttle.unselectedFieldList =
              this.unselectedFieldList;
            this.$refs.oneShuttle.loadingSeleccted = false;
          });
        })
        .catch((err) => {
          this.$refs.oneShuttle.loadingSeleccted = false;
        });
    },
    /**
     * 判断该字段是否支持排序功能
     * @param {Object} field:排序字段对象
     * @return {Boolean} 是否支持排序
     */
    isSort(field = {}) {
      return !(
        sortFidld.apiname.includes(field.schemefieldName) ||
        sortFidld.type.includes(field.schemefieldType)
      );
    },
    // 修改排序字段
    changeSortFiled: debounce(function (e, field, type) {
      // 判断该字段是否支持排序功能
      if (!this.isSort(field)) return;
      if (type) {
        type == 1 ? (field.sortDesc = false) : (field.sortAsc = false);
      } else {
        // 默认时是升序
        if (field.sortAsc) {
          field.sortAsc = false;
          field.sortDesc = true;
        } else {
          field.sortAsc = true;
          field.sortDesc = false;
        }
        // if (field.sortDesc) {
        //   field.sortAsc = true;
        //   field.sortDesc = false;
        // } else {
        //   field.sortAsc = false;
        //   field.sortDesc = true;
        // }
      }
      field.sortDesc && (this.$parent.sortDir = "desc");
      field.sortAsc && (this.$parent.sortDir = "asc");
      if (!field.sortDesc && !field.sortAsc) {
        this.$parent.sortDir = "";
      }
      if (e?.target?.tagName !== "I") {
        this.$emit("changeSortFiled", field);
      }
    }, 500),
    // 按‘字段名’排序
    changeFilter(field) {
      if (field.filterScreen) {
        this.$parent.showScreen = true;
        this.$emit("changeFilterFiled", field);
      } else {
        this.$parent.showScreen = false;
      }
      this.changeTableAttr(this.tableAttr);
    },
    // 日期格式化
    formatDate(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D}`;
      }
      return dateStr;
    },
    // 日期时间格式化
    formatDateTime(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      const h =
        dateRes.getHours() < 10 ? `0${dateRes.getHours()}` : dateRes.getHours();
      const m =
        dateRes.getMinutes() < 10
          ? `0${dateRes.getMinutes()}`
          : dateRes.getMinutes();
      const s =
        dateRes.getSeconds() < 10
          ? `0${dateRes.getSeconds()}`
          : dateRes.getSeconds();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D} ${h}:${m}:${s}`;
      }
      return dateStr;
    },
    // 自动换行
    wordWrap() {
      this.$emit("wordWrap", ...arguments);
    },
    // 锁定
    locked(item) {
      // 关闭锁定气泡
      this.$refs[`${item.schemefieldName}-popover`][0] &&
        this.$refs[`${item.schemefieldName}-popover`][0].doClose();
      this.$refs[`${item.schemefieldName}-popover`][1] &&
        this.$refs[`${item.schemefieldName}-popover`][1].doClose();
      this.$emit("locked", ...arguments);
    },
    // 文件下载
    downLoadFile(file) {
      // 先隐藏掉文件预览，上面的是粘贴回来的，点击附件直接下载文件，而不进行预览
      this.openPreview(file);
    },
    // 名称字段跳转路径
    nameHrefUrl(row) {
      // 个人客户相关列表(个人客户生成的联系人name字段跳转至个人客户详情页)
      if (!this.isNotPersonaccount && this.objectApi == "Contact") {
        return this.hrefPath(this.mainId);
      }
      //如果是服务报告、推送编号则禁止跳转
      else if (
        this.objectApi == "servicereport" ||
        this.objectApi == "ServiceReportPushRecord"
      ) {
        return row.id ? `#/commonObjects/detail/${row.id}/DETAIL` : "";
      } // 服务控制台跳转新个案详情
      else if (
        this.objectApi === "cloudcccase" &&
        this.$route.query.isConsole === "true"
      ) {
        return `javascript:void(0);`;
      } else {
        // 在跳转详情页前存储下当前视图id，方便在返回对象列表页时仍然显示对应视图内容
        this.$store.commit("setViewId", this.viewId);
        // todo 判断是否是新版服务控制台
        let newpath = this.hrefPath(row.id);
        return row.id ? newpath : "";
      }
    },
    // 根据是否是服务控制台返回相应的详情页path
    hrefPath(id) {
      let path;
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        // 是
        if (this.isJumpFlag) {
          // 跳转
          path = `#/commonObjects/console-multi-screen/console-detail/${id}/DETAIL?dataId=${id}`;
        } else {
          // 不跳转
          path = `javascript:void(0);`;
        }
      } else {
        path = `#/commonObjects/detail/${id}/DETAIL`;
      }
      return path;
    },
    // 所有人字段跳转路径
    hrefUrl(row, item) {
      // 项目管理-实际工作清单点击日期跳转详情
      if (
        this.$cookies.get("activeTabObjId") === "projectworklist" &&
        item.schemefieldType === "D"
      ) {
        this.$store.commit("setViewId", this.viewId);
        // todo 判断是否是新版服务控制台
        let path = this.hrefPath(row.id);
        return row[item.schemefieldName] ? path : "";
      } else if (item.apiname === "slaprocessid") {
        //如果是权利过程则禁止跳转
        return `javascript:void(0);`;
      } else if (
        item.apiname === "servicereporttemplateid" ||
        item.apiname === "reportid"
      ) {
        //如果是服务报告模板则禁止跳转
        return `javascript:void(0);`;
      } else if (row.type === "sellhoper" && item.schemefieldName === "name") {
        // todo 判断是否是新版服务控制台
        let path = this.hrefPath(row.id);
        return path;
      } else {
        this.$store.commit("setViewId", this.viewId);
        // todo 判断是否是新版服务控制台
        let newpath = this.hrefPath(row[item.schemefieldName]);
        return row[item.schemefieldName] ? newpath : `javascript:void(0);`;
      }
    },
    // url跳转
    jumpToUrl(url) {
      if (url.indexOf("http://") !== -1) {
        window.open(url);
      } else {
        window.open("http://" + url);
      }
    },
    // 进度条格式化
    formatProgress(percentage) {
      return "";
    },
    // 表格滚动懒加载
    append(params) {
      this.$emit("append");
    },
    reload() {
      // 刷新重置 offset 和表格
      this.params.offset = 0;
      this.tableData = [];

      // api动态加载完 开始重新请求数据
      this.$nextTick(() => {
        this.init(this.params);
      });
    },
    /**
     * 滚动加载更多数据
     */
    tableGetMore() {
      let that = this;
      const doms = document.querySelectorAll(
        ".content-main >#table>.el-table__body-wrapper"
      );
      const dom = doms[doms.length - 1];
      dom.addEventListener("scroll", (v) => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          const currentDate = this.$dayjs();
          //1秒钟之内只能请求一次
          if (currentDate - this.refreshDate > 1000) {
            //等于0证明已经到底，可以请求接口
            if (!this.noDataTip) {
              that.append();
            }
          }
          this.refreshDate = currentDate;
        }
      });
    },
    // 表格滚动懒加载
    clearSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    clearSelect() {
      this.selected = [];
    },
    // 表头操作
    handleAction(index, row, action) {
      if (arguments.length === 1) {
        action = index;
        this.$emit(action, action);
      } else {
        if (action === "handleDel") {
          this.$confirm(
            "此操作将永久删除该文件, 是否继续?",
            this.$i18n.t("label.prompt"),
            {
              confirmButtonText: this.$i18n.t("label.confirm"),
              cancelButtonText: this.$i18n.t("label.cancel"),
              type: "warning",
            }
          ).then(() => {
            this.$emit(action, index, row);
          });
        } else {
          this.$emit(action, index, row);
        }
      }
    },
    // 按钮权限判断
    ifButtonShow(rowData, btn) {
      // itemObj有值证明是相关列表的操作列,相关列表数据的按钮权限先通过this.itemObj.services（当前对象的权限）判断是否展示
      if (this.itemObj && this.itemObj.services) {
        //表单营销推广
        if (this.itemObj.objid === "campaignform") {
          return true;
        } else {
          if (
            btn.label === this.$i18n.t("pagecreator_page_button_edit") &&
            this.itemObj.services.modify
          ) {
            return true;
          } else if (
            btn.label === this.$i18n.t("component_setup_tabs_label_delete") &&
            this.itemObj.services.delete
          ) {
            return true;
          } else if (btn.action == "billing" || btn.action == "shipping") {
            //客户地址相关列表按钮处理
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (
          btn.label === this.$i18n.t("pagecreator_page_button_edit") &&
          (this.objModifyAll || this.objModify)
        ) {
          return true;
        } else if (
          btn.label === this.$i18n.t("component_setup_tabs_label_delete") &&
          (this.objModifyAll || this.objDelete)
        ) {
          return true;
        } else if (
          btn.label !== this.$i18n.t("pagecreator_page_button_edit") &&
          btn.label !== this.$i18n.t("component_setup_tabs_label_delete") &&
          (this.objModifyAll || this.objModify)
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 表格拖动列时触发的操作
    headerDragend(newWidth, oldWidth, column, event) {
      let fieldId = "";
      this.tableAttr.forEach((attr) => {
        if (attr.schemefieldName === column.property) {
          fieldId = attr.id;
        }
      });
      let params = {
        viewId: this.viewId,
        fieldId: fieldId,
        colwidth: Math.round(newWidth),
      };
      if (
        this.viewId === "0" ||
        this.viewId === "1" ||
        this.viewId === "2" ||
        this.viewId === "3"
      ) {
        params.viewId = `${this.prefix}_${this.viewId}`;
      }
      if (this.viewId !== "") {
        CommonObjApi.updateViewFieldMemory(JSON.stringify(params)).then(
          (res) => {
            if (res.result && res.returnCode === "1") {
            } else {
            }
          }
        );
      }
    },
    // 列表中操作
    handleTableAction(data, action) {
      let idx = data.$index + 1;
      let rowData = data.row;
      let actionName = action.action;
      let emitData = {
        index: idx, // 当前点击位置
        data: rowData, // 当前点击数据
        action: actionName, // 执行当前动作名称
        type: action.type || undefined, // 当前操作类别
        val: action.val || undefined, // 点击当前按钮派发的自定义值
      };
      // 若无管理员权限，判断按钮权限
      if (false) {
        this.$emit(actionName, emitData);
      } else if (data.row.attachtype) {
        this.$emit(actionName, emitData);
      } else if (
        this.itemObj !== undefined &&
        this.itemObj.objid === "campaignform"
      ) {
        //表单营销推广
        this.$emit(actionName, emitData);
      } else {
        CommonObjApi.getPermissionById({ id: data.row.id }).then((res) => {
          if (action.action === "del") {
            // 非锁定状态且有编辑权限时可删除
            if (res.data.isDelete && !res.data.isLocked) {
              this.$emit(actionName, emitData);
            } else {
              this.$message({
                showClose: true,
                message: this.$i18n.t("vue_label_notice_nopermission_delete"),
                type: "warning",
              });
              //抱歉，您无权删除该条数据，请联系系统管理员。
            }
          } else if (action.action === "edit") {
            if (res.data.isEdit) {
              this.$emit(actionName, emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权编辑该条数据，请联系系统管理员。"
                this.$i18n.t("report_label_norole")
              );
            }
          } else if (
            action.action !== "edit" &&
            action.action !== "del" &&
            action.action !== "marktemplate" &&
            action.action !== "unmarktemplate" &&
            action.action !== "billing" &&
            action.action !== "shipping"
          ) {
            if (res.data.canEditOwner) {
              this.$emit(actionName, emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权更改该条数据所有人，请联系系统管理员。""
                this.$i18n.t("report_label_norole")
              );
            }
          } else if (
            action.action == "marktemplate" ||
            action.action == "unmarktemplate"
          ) {
            if (res.data.isEdit) {
              this.$emit("marktemplate", emitData);
            } else {
              this.$message.warning(
                // "抱歉，您无权编辑该条数据，请联系系统管理员。"
                this.$i18n.t("report_label_norole")
              );
            }
          } else if (
            action.action == "billing" ||
            action.action == "shipping"
          ) {
            this.$emit("changedefaultaddress", emitData);
          }
        });
      }
    },
    //操作项 标记为模板切换
    checkboxmarktemplate(val) {
      if (val == "unmarktemplate") {
        this.tableButton.buttonList.splice(
          3,
          1,
          createButton({
            // 标记为模板
            label: this.$i18n.t("front-project-module-v1-market-is-template"),
            action: "marktemplate",
          })
        );
      } else {
        this.tableButton.buttonList.splice(
          3,
          1,
          createButton({
            // 取消标记模板
            label: this.$i18n.t("front-project-module-v1-unmarket-is-template"),
            action: "unmarktemplate",
          })
        );
      }
    },
    // 自定义序号序列化 分页序号
    indexMethod(idx) {
      idx += 1;
      let page = 0;
      if (this.currentPage && this.pageSizeList) {
        page = (this.currentPage - 1) * this.pageSizeList;
      }
      return page + idx;
    },
    // 编辑单元格
    editCell(item, row) {
      //项目管理行内编辑根据项目对里程碑、任务、成员做限制
      this.projectId = row.project_name || row.their_project;
      // 阻止内联编辑按钮冒泡事件
      event.preventDefault();

      // 根据Id获取数据权限
      CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
        if (res.data.isEdit) {
          // 点击单元格内编辑图标时不跳转
          this.dataId = row.id;
          this.rowData = Object.assign({}, row);
          this.changeAll = false;
          this.editableCellOptions = [];
          this.inputType = INPUTTYPE[item.schemefieldType];
          // this.showApply2Others =
          //   item.schemefieldType == "X" || item.schemefieldType == "MR"
          //     ? false
          //     : true;
          // 列表点击日期编辑处理
          if (item.type === "D") {
            this.toChangeValue = { value: new Date(row[item.schemefieldName]) };
          } else {
            this.toChangeValue = { value: row[item.schemefieldName] };
          }
          this.editableCellAttr = item;
          // 复选框类型无返回值时
          if (item.schemefieldType === "B") {
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined
                  ? false
                  : row[item.schemefieldName] === "true"
                  ? "true"
                  : "false",
            };
          } else if (
            item.schemefieldType === "X" &&
            item.apiname === "cloudcctag"
          ) {
            // 标签
            this.inputType = "tag";
            this.toChangeValue = { value: row["cloudcctagList"] };
          }
          // 查找、主详、查找多选字段
          else if (
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR"
          ) {
            this.relevantFieldId = item.id;
            this.relevantObjId = item.lookupObj;
            this.multiChecked = item.schemefieldType === "MR" ? true : false;
            this.editableCellOptions = [];
            if (
              row[item.schemefieldName] &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType !== "MR"
            ) {
              this.editableCellOptions = [
                {
                  label: row[`${item.schemefieldName}ccname`],
                  value: row[item.schemefieldName],
                },
              ];
            } else if (
              row[item.schemefieldName] &&
              row[item.schemefieldName] !== "" &&
              row[`${item.schemefieldName}ccname`] &&
              item.schemefieldType === "MR"
            ) {
              let values = row[item.schemefieldName].split(";");
              let labels = row[`${item.schemefieldName}ccname`].split(";");
              values.forEach((item, idx) => {
                this.editableCellOptions.push({
                  label: labels[idx],
                  value: values[idx],
                });
              });
              this.toChangeValue = { value: values };
            }
          } else if (
            item.schemefieldType === "L" ||
            item.schemefieldType === "Q" ||
            item.schemefieldType === "ct"
          ) {
            // 选择/多选/币种类型
            this.editableCellOptions = [];
            item.options.forEach((ele) => {
              this.editableCellOptions.push({
                label: ele.codevalue,
                value: ele.codekey,
              });
            });
          } else if (this.inputType === "number") {
            this.precision = item.decimalPlaces
              ? Number(item.decimalPlaces)
              : 0;
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 币种字段可能带币种类型，特殊处理
            if (item.schemefieldType === "c") {
              let changeValue = 0;
              if (
                row[item.schemefieldName] !== undefined &&
                row[item.schemefieldName] !== null
              ) {
                changeValue =
                  row[item.schemefieldName].indexOf("(") !== -1
                    ? row[item.schemefieldName].split("(")[0]
                    : row[item.schemefieldName];
                changeValue =
                  changeValue.indexOf(" ") !== -1
                    ? changeValue
                        .trim()
                        .split(" ")
                        [changeValue.trim().split(" ").length - 1].replace(
                          /,/g,
                          ""
                        )
                    : changeValue;
              }
              this.toChangeValue = { value: changeValue };
            }
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            let decimal =
              item.decimalPlaces !== undefined && item.decimalPlaces !== null
                ? 1 / Math.pow(10, Number(item.decimalPlaces))
                : 0;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              decimal;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) -
              decimal;
          } else if (this.inputType === "percent-number") {
            this.precision = Number(item.decimalPlaces);
            this.toChangeValue = {
              value:
                row[item.schemefieldName] === undefined ||
                row[item.schemefieldName] === null
                  ? row[item.schemefieldName]
                  : row[item.schemefieldName].replace(/,/g, ""),
            };
            // 控制内联编辑为null时不显示0
            this.toChangeValue.value =
              this.toChangeValue.value === null
                ? undefined
                : this.toChangeValue.value;

            let decimal =
              item.decimalPlaces !== undefined && item.decimalPlaces !== null
                ? 1 / Math.pow(10, Number(item.decimalPlaces))
                : 0;

            this.min =
              0 -
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) +
              decimal;
            this.max =
              Math.pow(10, item.schemefieldLength - item.decimalPlaces - 1) -
              decimal;
          } else if (this.inputType === "score") {
            this.schemefieldLength = Number(item.schemefieldLength);
            this.toChangeValue = { value: Number(row[item.schemefieldName]) };
          }
          // 如果是销售漏斗页面的内联编辑，点击小笔，进入词条记录的编辑弹窗
          if (this.isSalesFunnel) {
            this.$emit("salesFunnelEdit", item, row);
          } else {
            this.field = item.nameLabel;
            this.fieldApiName = item.apiname;
            this.dialogVisible = true;
            this.isEditApi = res.data.objectApi;
            this.$refs.editableCell && this.$refs.editableCell.setInputValue();
          }
        } else {
          this.$message.warning("抱歉，您无权编辑该条数据，请联系系统管理员。");
        }
      });
    },
    // 表格被双击
    cellDoubleClick(row, column) {
      if (!this.islineEdit) return;
      // 判断是否开启内联编辑
      if (
        this.$store.state.userInfoObj.enableInlineEdit === "false" ||
        !this.isClickCell
      )
        return;
      // 屏蔽市场活动 -> 表单营销推广 双击内敛编辑
      if (this.objId === "campaignform") return;
      // 附件不需要进行权限校验
      if (
        this.$route.params.type === "file" ||
        (this.itemObj && this.itemObj.relatedlistType === "attachement")
      ) {
        return;
      }
      // 查找要显示的数据类型
      let attr = this.tableAttr.filter((item) => {
        return item.schemefieldName === column.property;
      });
      // 事件及任务下的名称及相关项去除双击编辑
      if (
        attr[0].apiname === "relateobj" ||
        attr[0].apiname === "relateid" ||
        attr[0].apiname === "whoobj" ||
        attr[0].apiname === "whoid" ||
        (attr[0].apiname === "currency" && this.objectApi === "product") ||
        this.objectApi === "quotedetail" ||
        this.objectApi === "InvoicesItems" ||
        this.objectApi === "cloudccorderitem" ||
        this.objectApi === "purchaseorderitem" ||
        this.objectApi === "opportunitypdt"
      ) {
        return false;
      }
      // 实际人工成本、预计人工成本，项目任务/问题-总是持续时长不能进行双击编辑
      if (
        attr[0] &&
        (this.objectApi === "cloudcc_real_labor_costs" ||
          this.objectApi === "cloudccPlanCost" ||
          ((this.objectApi === "cloudccTask" ||
            this.objectApi === "CloudccProblem") &&
            attr[0].apiname === "continue_date") ||
          attr[0].apiname === "taskOrProblem" ||
          (this.objectApi === "CloudccProjectActualWorkList" &&
            attr[0].apiname === "project_name") ||
          attr[0].apiname === "their_project_name" ||
          attr[0].apiname === "project_name" ||
          attr[0].apiname === "their_project" ||
          (this.objectApi == "cloudccSubtask" &&
            attr[0].apiname === "their_milestone") ||
          attr[0].apiname === "principal" ||
          attr[0].apiname === "their_task" ||
          (this.objectApi === "cloudccSubtask" &&
            attr[0].apiname === "continue_date"))
      ) {
        return false;
      }
      // 项目、里程碑、任务、子任务对象--状态不显示列表行内编辑 根据完成百分比动态改变状态
      if (
        attr[0] &&
        (((this.objectApi === "CloudccProject" ||
          this.objectApi === "cloudccMilestone") &&
          attr[0].apiname === "status") ||
          ((this.objectApi === "cloudccSubtask" ||
            this.objectApi === "cloudccTask") &&
            attr[0].apiname === "task_status"))
      ) {
        return false;
      }
      // 判断对象编辑权限、字段编辑权限、记录编辑权限
      if (
        attr[0] &&
        attr[0].modify === "true" &&
        (this.objModifyAll || this.objModify) &&
        this.inlineedit
      ) {
        CommonObjApi.getPermissionById({ id: row.id }).then((res) => {
          if (res.data.isEdit) {
            this.dataId = row.id;
            this.rowData = Object.assign({}, row);
            this.changeAll = false;
            this.editableCellOptions = [];
            // this.showApply2Others = true;

            this.editableCellAttr = attr[0];
            // 图片、文件字段不可内联编辑
            if (
              attr[0].schemefieldType !== "IMG" &&
              attr[0].schemefieldType !== "FL"
            ) {
              if (attr[0].modify === "true") {
                this.inputType = INPUTTYPE[attr[0].schemefieldType];
                this.toChangeValue = { value: row[column.property] };
                // 复选框类型无返回值时
                if (
                  attr[0].schemefieldType === "B" &&
                  row[column.property] === undefined
                ) {
                  this.toChangeValue = {
                    value:
                      row[column.property] === undefined
                        ? false
                        : row[column.property] === "true",
                  };
                }
                // 标签
                else if (
                  attr[0].schemefieldType === "X" &&
                  attr[0].apiname === "cloudcctag"
                ) {
                  this.inputType = "tag";
                  this.toChangeValue = { value: row["cloudcctagList"] };
                }
                // 查找、主详、查找多选字段
                else if (
                  attr[0].schemefieldType === "Y" ||
                  attr[0].schemefieldType === "M" ||
                  attr[0].schemefieldType === "MR"
                ) {
                  this.relevantFieldId = attr[0].id;
                  this.relevantObjId = attr[0].lookupObj;
                  this.multiChecked =
                    attr[0].schemefieldType === "MR" ? true : false;
                  this.editableCellOptions = [];
                  if (
                    row[column.property] &&
                    row[`${column.property}ccname`] &&
                    attr[0].schemefieldType !== "MR"
                  ) {
                    this.editableCellOptions = [
                      {
                        label: row[`${column.property}ccname`],
                        value: row[column.property],
                      },
                    ];
                  } else if (
                    row[column.property] &&
                    row[column.property] !== "" &&
                    row[`${column.property}ccname`] &&
                    attr[0].schemefieldType === "MR"
                  ) {
                    let values = row[column.property].split(";");
                    let labels = row[`${column.property}ccname`].split(";");
                    values.forEach((item, idx) => {
                      this.editableCellOptions.push({
                        label: labels[idx],
                        value: values[idx],
                      });
                    });
                    this.toChangeValue = { value: values };
                  }
                }
                // 选项列表、选项列表多选
                else if (
                  attr[0].schemefieldType === "L" ||
                  attr[0].schemefieldType === "Q" ||
                  attr[0].schemefieldType === "ct"
                ) {
                  this.editableCellOptions = [];
                  attr[0].options.forEach((item) => {
                    this.editableCellOptions.push({
                      label: item.codevalue,
                      value: item.codekey,
                    });
                  });
                } else if (this.inputType === "number") {
                  this.precision = Number(attr[0].decimalPlaces);
                  this.toChangeValue = {
                    value:
                      row[column.property] === undefined ||
                      row[column.property] === null
                        ? row[column.property]
                        : row[column.property].replace(/,/g, ""),
                  };

                  // 币种字段可能带币种类型，特殊处理
                  if (attr[0].schemefieldType === "c") {
                    let changeValue = 0;
                    if (
                      row[column.property] !== undefined &&
                      row[column.property] !== null
                    ) {
                      changeValue =
                        row[column.property].indexOf("(") !== -1
                          ? row[column.property].split("(")[0]
                          : row[column.property];
                      changeValue =
                        changeValue.indexOf(" ") !== -1
                          ? changeValue
                              .trim()
                              .split(" ")
                              [
                                changeValue.trim().split(" ").length - 1
                              ].replace(/,/g, "")
                          : changeValue;
                    }
                    this.toChangeValue = { value: changeValue };
                  }
                  // 控制内联编辑为null时不显示0
                  this.toChangeValue.value =
                    this.toChangeValue.value === null
                      ? undefined
                      : this.toChangeValue.value;

                  this.min =
                    0 -
                    Math.pow(
                      10,
                      attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                    ) +
                    1;
                  this.max =
                    Math.pow(
                      10,
                      attr[0].schemefieldLength - attr[0].decimalPlaces - 1
                    ) - 1;
                } else if (this.inputType === "percent-number") {
                  this.precision = Number(item.decimalPlaces);
                  this.toChangeValue = {
                    value:
                      row[column.property] === undefined ||
                      row[column.property] === null
                        ? row[column.property]
                        : row[column.property].replace(/,/g, ""),
                  };
                  // 控制内联编辑为null时不显示0
                  this.toChangeValue.value =
                    this.toChangeValue.value === null
                      ? undefined
                      : this.toChangeValue.value;

                  this.min =
                    0 -
                    Math.pow(
                      10,
                      Number(item.schemefieldLength) -
                        Number(item.decimalPlaces) -
                        1
                    ) +
                    1;
                  this.max =
                    Math.pow(
                      10,
                      Number(item.schemefieldLength) -
                        Number(item.decimalPlaces) -
                        1
                    ) - 1;
                } else if (this.inputType === "score") {
                  this.schemefieldLength = Number(item.schemefieldLength);
                  this.toChangeValue = {
                    value: Number(row[item.schemefieldName]),
                  };
                }
                this.field = attr[0].nameLabel;
                this.fieldApiName = attr[0].apiname;
                this.dialogVisible = true;
                this.$refs.editableCell &&
                  this.$refs.editableCell.setInputValue();
              }
            }
          } else {
            this.$message.warning(this.$i18n.t("report_label_norole"));
          }
        });
      }
    },
    // 保存修改字段
    saveChange() {
      let fields = [];
      let datas = [];
      // TODO:查找和查找多选类型字段值修改
      let value = this.$refs.editableCell.editValue;
      let precision = this.$refs.editableCell.precision;
      let inputType = this.$refs.editableCell.inputType;
      //数字类型保存传值（小数位不够时补0）
      if (inputType === "number" && value) {
        value = value.toFixed(precision);
      }
      //列表行内编辑-项目管理系统合同金额
      if (this.editableCellAttr.apiname === "contract_amount") {
        let _value = value + "";
        if (_value.length > 10) {
          // 合同金额最多输入10位
          this.$message.warning(
            this.$i18n.t(
              "label.projectManagement.the.maximum.contract.amount.is.10.digits"
            )
          );
          return;
        }
      }
      //列表行内编辑-项目管理系统 任务工时小时数
      if (this.editableCellAttr.apiname === "daily_working_hours") {
        let _value1 = value;
        if (_value1 > 24 || _value1 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      //列表行内编辑-实际工作清单的实际工作时长
      if (this.editableCellAttr.apiname === "working_hours_billing") {
        let _value2 = value;
        if (_value2 > 24 || _value2 <= 0) {
          // 任务工时小时数必须小于24
          this.$message.warning(
            this.$i18n.t("label.projectManagement.positive.integer")
          ); //"请输入0-24之间的正整数"
          return;
        }
      }
      this.tableAttr.forEach((item) => {
        // 字段apiname取apiname或者schemefieldName不定
        fields.push(item.apiname || item.schemefieldName);
      });

      if (this.checkedList.length > 1 && this.changeAll) {
        this.checkedList.forEach((item) => {
          let data = { id: item.id };
          data[
            this.editableCellAttr.apiname ||
              this.editableCellAttr.schemefieldName
          ] =
            value === null || value === undefined ? value : this.format(value);
          datas.push(data);
        });
      } else {
        let data = { id: this.dataId };
        data[
          this.editableCellAttr.apiname || this.editableCellAttr.schemefieldName
        ] = value === null || value === undefined ? value : this.format(value);
        datas.push(data);
      }
      let regEmail =
        /^([a-z0-9A-Z]*[-_]*[\.]?)*@([a-z0-9A-Z]+(-[a-z0-9A-Z-]+)?\.)+[a-z0-9A-Z]{2,}$/;
      if (datas.length > 200) {
        // 最多应用于200条数据
        this.$message.warning(
          this.$i18n.t("vue_label_commonobjects_view_up_to_data")
        );
      } else if (
        this.fieldApiName === "twitter" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("twitter.com") === -1
      ) {
        // twitter、LinkedIn、Facebook字段加验证，必须带twitter.com、linkedin.com、facebook.com
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.twittererror")
        );
      } else if (
        this.fieldApiName === "linkedin" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("linkedin.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.linkedinerror")
        );
      } else if (
        this.fieldApiName === "facebook" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.indexOf("facebook.com") === -1
      ) {
        this.$message.warning(
          this.$i18n.t("APPIntegration.Social.facebookerror")
        );
      } else if (
        this.fieldApiName === "email" &&
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !regEmail.test(value)
      ) {
        this.$message.warning(
          this.$i18n.t("front-kefu-moudle-v1-yxgsbzq-reinput")
        );
      } else {
        //编辑项目任务列表
        if (
          window.location.href.substring(window.location.href.length - 3) ==
          "p03"
        ) {
          // 按钮加载中
          this.isSaveBtnLoading = true;
          CommonObjApi.getPermissionById({ id: datas[0].id })
            .then((res) => {
              let params = {
                objectApi: res.data.objectApi,
                jsonstr: JSON.stringify(datas),
                fields: fields.toString(),
              };
              CommonObjApi.updateViewListAjax(params)
                .then((res) => {
                  this.isSaveBtnLoading = false;
                  res.data.updateResultList.forEach((updateResult) => {
                    if (updateResult.errorMessage) {
                      this.$message.error(updateResult.errorMessage);
                      return;
                    } else {
                      this.$message.success(
                        this.$i18n.t(
                          "label.setup.mobile.bottombutton.change.success"
                        )
                      ); //"修改成功"
                      this.$emit("refresh");
                      this.checkedList = [];
                      this.dialogVisible = false;
                    }
                  });
                })
                .catch((err) => {
                  this.isSaveBtnLoading = false;
                });
            })
            .catch((err) => {
              this.isSaveBtnLoading = false;
            });
        } else {
          // 更新主记录的价格手册，删除原主记录子产品
          if (this.priceIsDelSon == true) {
            let paramsY = {
              objId: this.objId,
              recordId: this.recordId,
            };
            CommonObjApi.deleteRelatedProductInfo(paramsY).then((res) => {});
          }
          let params = {
            objectApi: this.objectApi || this.isEditApi,
            jsonstr: JSON.stringify(datas),
            fields: fields.toString(),
          };
          //输入值为空时不可保存
          this.isSaveBtnLoading = true;

          CommonObjApi.updateViewListAjax(params)
            .then((res) => {
              this.isSaveBtnLoading = false;
              const data = this.$refs.tablePanel.tableData;
              if (
                res.data !== null &&
                res.data.list &&
                res.data.list.length > 0
              ) {
                res.data.list.forEach((changeItem) => {
                  data.forEach((item, idx) => {
                    if (item.id === changeItem.id && item.name !== "") {
                      this.$set(
                        this.$refs.tablePanel.tableData,
                        idx,
                        changeItem
                      );
                      // 刷新  wwwjjj
                      if (this.$parent.getViewListData) {
                        this.$parent.getViewListData();
                      }
                    }
                  });
                });
              }
              // 处理提示语
              let returnInfo = "";
              res.data.updateResultList.forEach((updateResult) => {
                if (updateResult.isSuccess !== "true") {
                  returnInfo += this.$i18n.t(
                    "vue_label_notice_batchedit_fail",
                    [updateResult.id, updateResult.errorMessage]
                  );
                }
              });
              if (returnInfo === "") {
                //群策卡片
                //
                this.$message.success(
                  this.$i18n.t("vue_label_notice_change_success")
                );
                // 清空选中数据
                this.checkedList = [];
                this.dialogVisible = false;
                this.$refs.tablePanel.clearSelection();
              } else {
                this.$message.error(returnInfo);
              }
            })
            .catch(() => {
              this.isSaveBtnLoading = false;
            });
        }
      }
    },
    //格式转化
    format(val) {
      if (val.constructor == Array) {
        return val.join(";");
      } else {
        return val.toString();
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 内联编辑内容改变
    handleChange() {
      this.$emit("handleChange", ...arguments);
    },
    // 打开预览（详情页附件的表格中点击要预览的文件）
    openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = {
        name: item.name,
        type: "pdf",
        id: item.fileInfoId,
      };
      /**
       *  ["download", "viewinfo", "share",  "delete"]
       * 禅道bug：366附件预览文件仅支持（预览、下载）其他的功能不需要
       */
      this.$refs.previewFile.handleBtn(["download"]);
    },
    // 关闭预览
    closePreview() {
      this.showPreview = false;
    },
    /**
     * 服务控制台跳转方法  向二级tab中添加当前点击项
     * @param {Object} tabObj {id:对象id,value:对象名称}
     *
     */
    consoleJump(tabObj) {
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        // 是
        // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
        // 当前一级tab的对象
        let currentLevelTab = {};
        this.consoleData.data.list.forEach((level1Item) => {
          if (level1Item.id == this.consoleData.data.level1Id) {
            // 设置二级带单选中样式
            level1Item.level2Id = tabObj.id;
            // 当前一级tabid
            currentLevelTab.id = level1Item.id;
            // 当前一级tabname
            currentLevelTab.name = level1Item.name;
            // 当前一级菜单路由
            currentLevelTab.routerName = level1Item.routerName || "";
            currentLevelTab.routerPath = level1Item.routerPath || "";
            // 当前一级菜单路由参数
            currentLevelTab.params = level1Item.params || "";
            currentLevelTab.query = level1Item.query || "";
            level1Item.children = level1Item.children
              ? level1Item.children
              : [];
            // 判断children里是否存在此点击项 不存在再添加避免重复添加
            // 判断是否存在此点击项
            let res = level1Item.children?.some((cItem) => {
              return cItem.id == tabObj.id;
            });
            if (!res) {
              // 如果不存在再push
              level1Item.children.push({
                id: tabObj.id,
                name: tabObj.value,
              });
              // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
              let flag = level1Item.children.some((cItem) => {
                return cItem.id == this.consoleData.data.level1Id;
              });
              if (!flag) {
                level1Item.children.unshift(currentLevelTab);
              }
            }
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      }
    },
    // 跳转事件
    jumpTo(item, scope, cb, flag) {
      // 个人客户相关列表--列表式(个人客户生成的联系人name字段跳转至个人客户详情页)
      if (scope.row.objApi && scope.row.objApi === "Contact") {
        this.hrefPath(this.mainId);
      }

      // }
      // 服务控制台跳转新个案详情
      if (
        this.objectApi === "cloudcccase" &&
        this.$route.query.isConsole === "true" &&
        item.apiname === "name"
      ) {
        this.$store.commit("setViewId", this.viewId);
        this.$router.push({
          path: "/controlPanel/control",
          query: {
            caseId: scope.row.id,
            caseOrder: scope.row.name,
            viewId: this.viewId,
          },
        });
        return;
      }
      // 判断服务云下的权利下的权利过程根据简档权限跳转lightning设置
      if (item.apiname === "slaprocessid") {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              isGoDetail: true,
              goDetailId: scope.row.slaprocessid,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
        return;
      }
      // 判断服务云下的工作订单、工作订单行项目、工作类型详情下的服务报告模板根据简档权限跳转lightning设置
      if (item.apiname === "servicereporttemplateid") {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          let templateObj = scope.row;
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              type: "serviceReportTemplate",
              id: templateObj.servicereporttemplateid,
              label: templateObj.servicereporttemplateidccname,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
        return;
      }
      // 判断控制台样式情况下打开，点击相关列表打开二级菜单
      if (this.$store.state.navigationStyle && this.isClickCell) {
        this.isJumpFlag = flag;
        if (!this.isJumpFlag) {
          this.consoleData.data.list.forEach((level1Item) => {
            if (level1Item.id == this.consoleData.data.level1Id) {
              // 记录当前二级菜单选中状态
              this.level2Id = level1Item.level2Id;
            }
          });
        }
        let name = "";
        let id = "";
        if (
          item.schemefieldType == "Y" ||
          item.schemefieldType == "R" ||
          item.schemefieldType == "M"
        ) {
          name = scope.row[item.schemefieldName + "ccname"];
          id = scope.row[item.schemefieldName];
        } else {
          name = scope.row[item.schemefieldName];
          id = scope.row.id;
        }
        let menuObj = {
          name: name,
          id: id,
          routerPath: `/commonObjects/console-multi-screen/console-detail/${id}/DETAIL`,
          query: {
            dataId: id,
          },
        };
        window.$CCDK.CCMenu.addMenu2(menuObj);
        if (!this.isJumpFlag) {
          // 不跳转也不需要改变二级菜单状态 addMenu2会改变菜单选中状态  在这里还原一下
          this.consoleData.data.list.forEach((level1Item) => {
            if (level1Item.id == this.consoleData.data.level1Id) {
              // 记录当前二级菜单选中状态
              level1Item.level2Id = this.level2Id;
            }
          });
        }
        return;
      }
      if (event.target.tagName !== "IMG") {
        if (this.isClickCell) {
          let tabObj = {
            id: scope.row.id,
            value: scope.row.name,
          };
          this.consoleJump(tabObj);
        }

        let idx = scope.$index + 1;
        let rowData = scope.row;
        let emitData = {
          field: item,
          index: idx, // 当前点击位置
          data: rowData, // 当前点击数据
        };
        this.$emit(cb, emitData);
      }
      // cb && cb(scope)
    },
    // 单选事件
    select(selection, row) {
      // shift选中使用
      const data = this.$refs.tablePanel.tableData; // 获取所有数据
      const origin = this.origin; // 起点数 从-1开始
      const endIdx =
        selection[selection.length - 1] &&
        selection[selection.length - 1].index; // 终点数

      // 判断设置成选中还是未选中
      this.checkedList = selection;
      //itemObj有数据证明是相关列表（attachChecked文件相关列表需要）
      if (this.itemObj !== undefined && !this.useLoadMore) {
        let selected = selection.length && selection.indexOf(row) !== -1; //为true时选中，为 0 时（false）未选中
        if (selected === true) {
          this.checkedList.forEach((item) => {
            this.$set(item, "attachChecked", true);
          });
        } else {
          this.$delete(row, "attachChecked");
        }
      }
      if (this.pin && selection.includes(data[origin])) {
        // 判断按住
        const sum = Math.abs(origin - endIdx) + 1; // 这里记录终点
        const min = Math.min(origin, endIdx); // 这里记录起点
        let i = 0;
        while (i < sum) {
          const index = min + i;
          let checked = this.checkedList.find((checked) => {
            return checked.id === data[index].id;
          });
          if (checked === undefined) {
            this.checkedList.push(data[index]);
          }
          this.$refs.tablePanel.toggleRowSelection(data[index], true); // 通过ref打点调用toggleRowSelection方法，第二个必须为true
          i++;
        }
      } else {
        //
        this.origin = row.index; // 记录起点
      }
      // 下拉加载更多无需记录多选表格数据
      if (!this.useLoadMore) {
        //记录表格多选数据
        this.allRow.forEach((item, index) => {
          if (item.id == row.id) {
            this.allRow.splice(index, 1);
          }
        });
        this.allRow = this.allRow.concat(selection);
        this.allRow = this.unique(this.allRow);
      }
    },
    rowClick(row, column, event) {
      this.$emit("rowClick", row, column);
    },
    // 全选事件
    selectAll(selection) {
      this.$emit("onchange", selection);
      this.allRow = selection;
      this.allRow = this.unique(this.allRow);
      // 如果全选 将全部列表元素插入到已选列表 否则全部删除列表
      let copyArr = selection;
      selection = this.dataList;
      selection.forEach((row) => {
        this.handlerChecked(row, !emptyArray(copyArr));
      });
      // 可能修改: 在调用getCheckedList时返回this.checkedList,为了在全选时也能使用
      if (!this.useLoadMore) {
        this.checkedList =
          this.checkedList.length === this.pageObj.dataList.length
            ? []
            : selection;
      }
      //itemObj有数据证明是相关列表（attachChecked文件相关列表需要）
      if (this.itemObj !== undefined) {
        //全选添加attachChecked为true
        if (this.checkedList.length > 0) {
          this.checkedList.forEach((item) => {
            this.$set(item, "attachChecked", true);
          });
        } else {
          //取消全选删除
          selection.forEach((item) => {
            this.$delete(item, "attachChecked");
          });
        }
      }
      this.$emit("selectAll", selection, this.memoryCheckList);
      // 开启下拉加载，不会进行分页
      if (!this.useLoadMore) {
        this.handleSelect(selection);
      }
    },
    /**
     * 添加人：张亚欣
     * 添加原因：记录分页选中数据
     * **/
    handleSelect(val) {
      const hasSave = this.selected.find((item) => {
        return item.page === this.pageObj.page;
      });
      if (hasSave) {
        hasSave.rows = this.dataList.filter((item) => {
          return val.includes(item);
        });
      } else {
        this.selected.push({
          page: this.pageObj.page,
          rows: val,
        });
      }
    },
    // 处理全选反选中的冗余代码
    handlerChecked(row, isNotAllCheck) {
      if (row.id) {
        let id = row.id;
        let idx = this.memoryCheckList.indexOf(id);
        // 如果不存在 加入
        if (idx === -1 && !isNotAllCheck) {
          this.memoryCheckList.push(id);
        }
        // 如果存在 直接删除
        if (idx > -1 && isNotAllCheck) {
          this.memoryCheckList.splice(idx, 1);
        }
      }
    },
    sortBy(props) {
      return function (a, b) {
        return a[props] - b[props];
      };
    },
    // 表格选中状态
    selectionChange(rows) {
      this.$emit("onchange", this.checkedList);
      const data = this.$refs.tablePanel.tableData;
    },
    // 获取被选中列表
    getCheckedList() {
      // 如果开启分页记录 memorycheckList中的所有数据
      if (this.memory) {
        // 将当前页的数据保存 memorycheckList中 防止bug
        return this.memoryCheckList;
      } else {
        return this.checkedList;
      }
    },
    clearMemoryList() {
      this.memoryCheckList = [];
    },
    toggleSelection() {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.tablePanel.toggleRowSelection(row);
        });
      } else {
        this.$refs.tablePanel.clearSelection();
      }
    },
    getSelected() {
      if (this.selected.length === 0) {
        return [];
      }
      let result = [];
      this.selected.forEach((item) => {
        result = [...result, ...item.rows];
      });
      return result;
    },
    echo(val) {
      this.echoSelected = val;
    },
    unique(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].id === arr[j].id) {
            arr.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return arr;
    },
    memberFun(val) {
      this.str = val;
    },

    //  电话回复
    callPhone(phone) {
      this.$bus.$emit("newCLick", phone);
    },
  },
  computed: {
    ...mapState(["consoleData"]),
    userCheckedCount() {
      return this.memory ? this.selectedList.length : this.checkedList.length;
    },
    /**
     * 是否展示操作列
     */
    showButtonCol() {
      let flag = false;
      if (Array.isArray(this.tableButton.buttonList)) {
        this.tableButton.buttonList.forEach((item) => {
          flag = flag || this.ifButtonShow("", item);
        });
      }
      return flag;
    },
  },
  watch: {
    allRow() {
      //监测选中的多选数据
      this.$emit("allRow", this.allRow);
    },
    //返回的多选选中的数据
    backchecked: {
      handler() {
        this.allRow = this.backchecked;
      },
      immediate: true,
    },
    // 监听弹框的是否为true，true时清空字段装下标的数组
    showEditTableFieldPopover() {
      if (this.showEditTableFieldPopover == true) {
        this.$bus.$emit("clearIndexArr");
      }
    },
    searchField(nval, oval) {
      setTimeout(() => {
        if (nval === "") {
          this.unselectedFieldList = this.allSelectedFieldList;
        } else {
          this.unselectedFieldList = this.allSelectedFieldList.filter(
            (item) => {
              return item.label.indexOf(nval) !== -1;
            }
          );
        }
      }, 100);
    },
    unselectedFieldList: {
      deep: true,
      handler(nval) {
        nval.length > 0 &&
          nval.forEach((field) => {
            let isExist = false;
            this.allSelectedFieldList.forEach((item) => {
              if (item.id === field.id) {
                isExist = true;
              }
            });
            if (!isExist) {
              this.allSelectedFieldList.push(field);
            }
          });
      },
    },
    selectedFieldList: {
      deep: true,
      handler(nval) {
        nval.length > 0 &&
          nval.forEach((field) => {
            this.allSelectedFieldList = this.allSelectedFieldList.filter(
              (item) => {
                return item.id !== field.id;
              }
            );
          });
      },
    },
    pageObj: {
      // 分页数据发生改变时候选中选项框
      handler: function (oldVal, newVal) {
        // 定时器确保表格已经刷新
        setTimeout(() => {
          // 数据更新
          this.dataList = this.pageObj.dataList;
          this.dataList &&
            this.dataList.forEach((item, index) => {
              item.index = index;
            });
          // 打印传递数据
          this.$nextTick(() => {
            //解决第一次触发emit无效问题
            this.$store.state.printList = this.dataList;
          });
          if (this.useLoadMore) {
            this.getMoreData = this.pageObj.dataList.slice(0, 50);
          }
          // 创建选中
          if (!newVal || !newVal.dataList) {
            return false;
          }

          // 记录上次选中数据
          // TODO:修改选中之前的数据状态
          newVal.dataList.forEach((item) => {
            if (item.checked !== undefined) {
              this.$refs.tablePanel.toggleRowSelection(item);
            }
          });
        }, 20);
      },
      deep: true,
    },
    objId(nVal, oVal) {
      this.getObjectPermission(nVal);
    },
  },
};
</script>
<style lang="scss" scoped>
// 列表操作按钮
.tableButton {
  margin-top: 4px;
  cursor: pointer;
  width: 14px;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}

// 表头锁定按钮
.lockedImg {
  margin-top: 4px;
  width: 11px;
}

.content-main {
  position: relative;
  clear: both;
  height: 100%;
}

.showEditTableField-box {
  position: absolute;
  top: 11px;
  left: 18px;
  z-index: 100;
}

.action-btn {
  color: #006dcc !important;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  color: #ccc;
  float: left;
  display: contents;
}

.stars span {
  font-size: 20px;
  margin-left: 3px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

.table-head-tool {
  .el-input {
    position: absolute;
    top: -3px;
    left: -10px;
    margin-left: 10px;
    // width: calc(100% - 40px);
  }

  ::v-deep .el-input__inner {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    height: 25px;
    padding-left: 0;
  }

  .dragBox {
    width: 49%;
    height: 285px;
  }

  ::v-deep .el-popper[x-placement^="top"] .popper__arrow::after {
    border-top-color: #283c79 !important;
  }

  .item-box-left-container {
    border: 1px solid #ddd;
    border-radius: 4px;
    min-height: 250px;
    max-height: 250px;

    .searchBox {
      position: relative;
      padding: 0 10px;
      top: 5px;
      height: 25px;
      margin-bottom: 0;

      i {
        position: absolute;
        z-index: 100;
      }

      ::v-deep .el-input__inner {
        padding-left: 32px;
      }
    }
  }

  .itemBox {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px 0;
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
  }

  .itemBox-left {
    border: none;
    min-height: 200px;
    max-height: 222px;
    margin-top: 2px;
  }

  .dragItem {
    width: 100%;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    padding: 0 10px;
    cursor: move;

    &:hover {
      background: #fafaf9;
    }
  }

  .btns {
    width: 95%;
    margin: 10px 15px 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
  }
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 0;
  width: calc(100% - 20px);
}

::v-deep .el-progress-bar__outer {
  margin-right: 10px;
}

.fileItem {
  color: #489ad9;
  cursor: pointer;
  margin-right: 5px;
}

::v-deep .el-dialog__header {
  text-align: left;
}

::v-deep .el-table-column--selection .cell {
  text-align: center;
}

::v-deep .el-table__fixed-body-wrapper {
  // top: 48px !important;
}

::v-deep .el-dialog__footer {
  clear: both;
}

// 表格纵向滚动条
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: auto;
}

.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}

// 排序图标
.sortImg {
  height: 12px;
  display: inline-block;
}

// 编辑图标样式
.editIconImg {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  height: 12px;
}

// 提示语
.noDataTip {
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}

// 表头样式
.el-table--border,
.el-table--group {
  border: 1px solid #dedcda;
}

::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
// 新版表格统一表头高度36px
::v-deep .el-table__header tr th {
  padding: 6px;
  padding-left: 0;
  padding-right: 0;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #dedcda;
  border-color: #dedcda;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

// 进度条样式
.el-progress {
  display: inline;
}

::v-deep .el-table td:first-child .cell {
  padding-right: 14px;
}

// 表头颜色自定义、单元格超出...显示
::v-deep .el-table__header {
  img {
    margin-top: -4px;
  }

  tr th {
    background: #fafaf9;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th .cell {
    padding-right: 14px;

    .el-checkbox {
      margin-left: 0;
    }
  }
}

.admin_s {
  margin: 4px 0 0 0px;
  cursor: pointer;
  width: 14px;
}

::v-deep .el-table th .cell {
  min-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

// 新版表格行统一高度36px
::v-deep .el-table td {
  position: relative;
  padding: 6px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;

  .cell {
    min-height: 23px;
    position: relative;

    > span,
    > a {
      width: calc(100% - 1px);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      > span > img {
        right: 5px !important;
      }
    }
    .user_box {
      line-height: 23px;
      height: 23px;
      display: flex;
      .headPortrait {
        .logoname {
          font-size: 12px !important;
          line-height: 23px;
        }
      }
      a {
        width: calc(100% - 27px);
        margin-left: 5px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

::v-deep .el-table--border td {
  border-right: none;
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
}
::v-deep .is-checked {
  .el-checkbox__input {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .el-checkbox {
  .is-indeterminate {
    .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
  }
}
::v-deep .edit_box {
  .editableCell {
    .el-checkbox {
      .el-checkbox__inner {
        margin-top: 8px !important;
      }
    }
  }
}
::v-deep .el-input__prefix {
  //wj
  top: 0 !important;
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.cannotEdit {
  box-sizing: border-box;
  padding-right: 15px;
}
</style>
<style lang="scss">
.cerialNumber {
  div {
    width: 30px;
  }
}
.table-popover-span {
  .el-checkbox {
    display: block;
  }
}
</style>

